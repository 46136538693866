import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'

import noResult from 'assets/img/no-result.svg'

export type NoResultProps = {
  title: ReactNode
  description?: ReactNode
  content?: ReactNode
  sx?: SxProps
}

export default function NoResult({
  title,
  description,
  content,
  sx,
}: NoResultProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Box
        component="img"
        src={noResult}
        alt="No data"
        width={120}
        height={144}
        mb={3}
      />
      <Typography variant="h6">{title}</Typography>
      {description && (
        <Typography variant="body1" color="text.secondary" mt={1.5}>
          {description}
        </Typography>
      )}
      {content && <Box mt={3}>{content}</Box>}
    </Box>
  )
}
