import dayjs from 'dayjs'

import { LabelFieldType } from 'types'

export function getLabelFieldValue(
  value: string | number | boolean,
  type: LabelFieldType,
  gettext: (text: string) => string
) {
  switch (type) {
    case 'bool':
      return value ? gettext('Yes') : gettext('No')
    case 'date':
      return dayjs(value as string).format('DD MMM, YYYY')
    default:
      return value
  }
}
