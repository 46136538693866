import { RequestStatus } from 'types'

const beforeCompletionStatuses: RequestStatus[] = ['sent', 'expired']

export function isActionAllowed(status: RequestStatus) {
  return status !== 'complete'
}

export function isCompleteAllowed(status: RequestStatus) {
  return isActionAllowed(status) && !beforeCompletionStatuses.includes(status)
}
