import dayjs from 'dayjs'

export default function getAgeBackground(dob: string | null) {
  const diff = dayjs.duration(dayjs().diff(dayjs(dob)))
  const years = diff.years()
  if (!years) {
    return 'aquaHaze.main'
  }

  if (years >= 5) {
    return 'travertine.main'
  }

  return 'mintcream.main'
}
