import { Choice, PatientStatus } from 'types'

export function getPatientStatus(
  gettext: (text: string) => string,
  status: PatientStatus
) {
  const choices: Record<PatientStatus, Choice> = {
    completed: {
      value: 'completed' as PatientStatus,
      label: gettext('Completed'),
    },
    in_progress: {
      value: 'in_progress' as PatientStatus,
      label: gettext('In progress'),
    },
  }

  return choices[status]
}
