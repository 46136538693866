import { FocusEvent } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CachedIcon from '@mui/icons-material/Cached'
import dayjs from 'dayjs'

import { RequestType } from 'types'
import api from 'common/services/api'
import getIdFromUuid from 'common/utils/getIdFromUuid'
import { interpolate, useTranslations } from 'common/services/translations'
import { isActionAllowed } from 'common/utils/requestHelpers'
import { useChoice } from 'common/hooks/useChoices'
import LabeledText from 'common/components/LabeledText'
import ModalTrigger from 'common/components/ModalTrigger'

import Form from '../Form'
import Status from '../Status'
import RequestLabelBlock from '../LabelBlock'

type Props = {
  request: RequestType
  refetch: () => void
}

export default function Details({ request, refetch }: Props) {
  const { gettext } = useTranslations()
  const reason = useChoice(
    'Request.cancellation_reason',
    request.cancellation_reason || ''
  )?.label

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    const note = event.target.value
    return api
      .patch(`patients/requests/${request.uuid}`, { note })
      .then(() => refetch())
  }

  return (
    <>
      <Grid
        container
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        mb={1.5}
      >
        <Grid container width="auto">
          <Typography variant="subtitle1" fontWeight={600} mr={2}>
            {interpolate(gettext('Request #%s'), [getIdFromUuid(request.uuid)])}
          </Typography>
          <Status type="request" status={request.status} />
        </Grid>
        {isActionAllowed(request.status) && (
          <div>
            <ModalTrigger
              title={gettext('Resend Request')}
              description={interpolate(
                gettext(
                  'Current request #%s will be canceled, provide the cancellation reason'
                ),
                [getIdFromUuid(request.uuid)]
              )}
              component={Form}
              componentProps={{
                initialValues: {
                  cancellation_reason: '',
                  patient: request.patient,
                  note: request.note,
                  form: request.form,
                  additional_sms_text: request.additional_sms_text,
                },
                resendId: request.uuid,
                refetch,
              }}
            >
              <Tooltip title={gettext('Resend Request')}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ minWidth: 'auto' }}
                >
                  <CachedIcon fontSize="small" color="secondary" />
                </Button>
              </Tooltip>
            </ModalTrigger>
          </div>
        )}
      </Grid>
      {request.cancellation_reason && (
        <Typography
          component="p"
          variant="caption"
          color="text.disabled"
          fontWeight={500}
          fontStyle="italic"
          mb={1.5}
        >
          <Box component="span" sx={{ color: 'text.primary' }}>
            {gettext('Resolution')}:
          </Box>{' '}
          {reason}
        </Typography>
      )}
      <Grid container justifyContent="space-between" mb={1.5}>
        <Typography
          fontSize="0.75rem"
          lineHeight={1.67}
          fontWeight={500}
          color="action.active"
          fontStyle="italic"
        >
          {interpolate(gettext('%s, created by %s'), [
            dayjs(request.created).format('D MMMM, YYYY'),
            request.created_by?.name || '',
          ])}
          {request.completed_by &&
            `, ${interpolate(gettext('completed by %s'), [
              request.completed_by?.name || '',
            ])}`}
        </Typography>
      </Grid>
      {request.response && <RequestLabelBlock request={request} />}
      <Grid container columnSpacing={1} rowSpacing={1.5}>
        <Grid item xs={6}>
          <LabeledText
            label={gettext('Request type')}
            content={request.form_name}
          />
        </Grid>
        {request.additional_sms_text && (
          <Grid item xs={6} ml="auto">
            <LabeledText
              label={gettext('Additional SMS Text ')}
              content={request.additional_sms_text}
            />
          </Grid>
        )}
      </Grid>
      <TextField
        defaultValue={request.note}
        label={gettext('Notes')}
        placeholder={gettext('Add some notes')}
        multiline
        sx={{ width: '100%', mt: 3 }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: 1000 }}
        onBlur={handleBlur}
      />
    </>
  )
}
