import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'

import { interpolate, useTranslations } from 'common/services/translations'

import useStyles from './useStyles'

type Props = {
  children?: ReactNode
  enabled: boolean
  inputValue: string
  onClick: () => void
}

export default function PatientsList({
  children,
  enabled,
  inputValue,
  onClick,
  ...props
}: Props) {
  const { gettext } = useTranslations()
  const styles = useStyles()

  return (
    <Paper {...props} sx={styles.paper}>
      {children}
      {enabled && (
        <Button
          fullWidth
          variant="text"
          sx={styles.addBtn}
          onClick={onClick}
          onMouseDown={(event) => {
            // Prevent input blur before button click event
            event.preventDefault()
          }}
        >
          <Box sx={styles.btnWrapper}>
            <PersonAddAltIcon sx={styles.btnIcon} />
          </Box>
          <Typography
            variant="subtitle1"
            color="text.primary"
            dangerouslySetInnerHTML={{
              __html: interpolate(gettext('Add “%s”'), [
                `<strong>${inputValue}</strong>`,
              ]),
            }}
          />
        </Button>
      )}
    </Paper>
  )
}
