import { useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Formik, Form as FormikForm, FormikHelpers, Field } from 'formik'
import { TextField } from 'formik-mui'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'

import {
  Hospital,
  PaginationResponse,
  BasePatient,
  PatientCreate,
  PatientResponse,
  PatientUpdate,
  ResponseItemType,
} from 'types'
import { access, CheckAccess, useCheckAccess } from 'common/access'
import { useAuth } from 'common/services/auth'
import { interpolate, useTranslations } from 'common/services/translations'
import {
  NHS_MASK,
  PHONE_MASK,
  PHONE_PLACEHOLDER,
} from 'common/constants/common'
import useFormSubmit from 'common/hooks/useFormSubmit'
import {
  AsyncSelectField,
  ChoicesSelectField,
} from 'common/components/SelectField'
import DatePicker from 'common/components/DatePicker'
import MaskField from 'common/components/MaskField'
import { parseNHS } from 'common/utils/formatters/formatNHS'

import getValidationSchema from './validationSchema'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

type PatientValues = Omit<BasePatient, 'date_of_birth' | 'nhs'> &
  Partial<ResponseItemType> & {
    date_of_birth?: string | null
    nhs?: string
    hospital?: string
    postal_code: string
    ethnicity_description?: string | null
  }

const defaultValues: PatientValues = {
  first_name: '',
  last_name: '',
  date_of_birth: null,
  nhs: '',
  ethnicity: '',
  ethnicity_description: undefined,
  guardian_first_name: '',
  guardian_last_name: '',
  guardian_relationship: '',
  contact_number: '',
  email: '',
  hospital: '',
  postal_code: '',
}

type Props = {
  initialValues?: PatientValues
  id?: string
  onClose: () => void
  refetch: (patientID: string) => void
}

export default function Form({
  initialValues = defaultValues,
  id,
  onClose,
  refetch,
}: Props) {
  const isEdit = Boolean(id)

  const { user, currentStudy } = useAuth()
  const { gettext } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const [isStudyAdmin] = useCheckAccess(access.F_STUDY_ADMIN)
  const { data: patient, isLoading } = useQuery<PatientResponse>({
    queryKey: [`patients/${id}`],
    enabled: isEdit,
  })

  const { generalError: errorCreate, submitForm: submitCreate } = useFormSubmit<
    PatientCreate,
    { id: string }
  >('patients')
  const { generalError: errorUpdate, submitForm: submitUpdate } = useFormSubmit<
    PatientUpdate,
    { id: string }
  >(`patients/${id}`, 'patch')

  const generalError = errorCreate || errorUpdate

  const validationSchema = useMemo(
    () => getValidationSchema(gettext, !isEdit && isStudyAdmin),
    [gettext, isEdit, isStudyAdmin]
  )

  const handleSuccess = useCallback(
    (patientID: string, patient: PatientValues, isCreating = false) => {
      enqueueSnackbar(
        interpolate(
          isCreating
            ? gettext('Patient %s successfully created')
            : gettext('Patient %s successfully updated'),
          [`${patient.first_name} ${patient.last_name}`]
        ),
        { variant: 'success' }
      )
      refetch(patientID)
      onClose()
    },
    [enqueueSnackbar, gettext, refetch, onClose]
  )

  const handleCreate = (
    data: PatientValues,
    actions: FormikHelpers<PatientCreate>
  ) =>
    submitCreate({
      data: {
        ...data,
        nhs: parseNHS(data.nhs),
        study: currentStudy!.uuid,
        hospital: data.hospital || user!.hospital.uuid,
        date_of_birth: dayjs(data.date_of_birth).format('YYYY-MM-DD'),
        ethnicity_description: data.ethnicity?.includes('other')
          ? data.ethnicity_description ?? ''
          : '',
      },
      actions,
      onSuccess: ({ id }) => handleSuccess(id, data, true),
    })

  const handleUpdate = (
    data: PatientValues,
    actions: FormikHelpers<PatientUpdate>
  ) =>
    submitUpdate({
      data: {
        ...data,
        nhs: parseNHS(data.nhs),
        uuid: data.uuid!,
        date_of_birth: dayjs(data.date_of_birth).format('YYYY-MM-DD'),
        ethnicity_description: data.ethnicity?.includes('other')
          ? data.ethnicity_description ?? ''
          : '',
      },
      actions,
      onSuccess: ({ id }) => handleSuccess(id, data),
    })

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...patient,
        nhs: patient?.nhs?.toString(),
        hospital: patient?.hospital.uuid ?? '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      //@ts-expect-error onSubmit inherits PatientValues from initialValues which is wrong
      onSubmit={isEdit ? handleUpdate : handleCreate}
    >
      {({ isSubmitting, values: { ethnicity } }) => (
        <Box component={FormikForm} mt={4}>
          <Backdrop
            open={isLoading}
            sx={{
              position: 'absolute',
              zIndex: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          >
            <CircularProgress />
          </Backdrop>
          {generalError && (
            <Alert severity="error" color="error" sx={{ mb: 2 }}>
              <AlertTitle>{gettext('Error')}</AlertTitle>
              {generalError}
            </Alert>
          )}
          <Grid container rowSpacing={4} columnSpacing={1}>
            <CheckAccess access={access.F_STUDY_ADMIN}>
              <Grid item xs={12}>
                <Field
                  component={AsyncSelectField<Hospital>}
                  name="hospital"
                  endpoint="hospitals"
                  disabled={isEdit}
                  queryOptions={{
                    select: (data: PaginationResponse<Hospital>) =>
                      data.results,
                  }}
                  valueKey="uuid"
                  labelKey="title"
                  label={gettext('Hospital')}
                  placeholder={gettext('Select the Hospital')}
                  inputLabel={{ shrink: true }}
                  formControl={{ sx: { width: '100%' } }}
                  notched
                  displayEmpty
                />
              </Grid>
            </CheckAccess>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="first_name"
                label={gettext('Patient First Name')}
                placeholder={gettext('First Name')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="last_name"
                label={gettext('Patient Last Name')}
                placeholder={gettext('Last Name')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={DatePicker}
                name="date_of_birth"
                label={gettext('Patient DOB')}
                placeholder={gettext('Select date')}
                disableFuture
                sx={{ width: '100%' }}
                views={['year', 'month', 'day']}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={MaskField}
                name="nhs"
                label={gettext('Patient NHS #')}
                placeholder={gettext('000 000 0000')}
                mask={NHS_MASK}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={ChoicesSelectField}
                name="ethnicity"
                path="Patient.ethnicity"
                label={gettext('Ethnicity')}
                placeholder={gettext('Select ethnicity')}
                inputLabel={{ shrink: true }}
                formControl={{ sx: { width: '100%' } }}
                notched
                displayEmpty
              />
            </Grid>
            {ethnicity?.includes('other') && (
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="ethnicity_description"
                  label={gettext('Ethnicity description')}
                  placeholder={gettext('Please describe your ethnicity')}
                  sx={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="guardian_first_name"
                label={gettext('Guardian First Name')}
                placeholder={gettext('Guardian First Name')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name="guardian_last_name"
                label={gettext('Guardian Last Name')}
                placeholder={gettext('Guardian Last Name')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={ChoicesSelectField}
                name="guardian_relationship"
                path="PatientPID.guardian_relationship"
                label={gettext('Guardian Relationship')}
                placeholder={gettext('Select Relationship')}
                inputLabel={{ shrink: true }}
                formControl={{ sx: { width: '100%' } }}
                notched
                displayEmpty
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={MaskField}
                name="contact_number"
                type="tel"
                label={gettext('Contact Number')}
                placeholder={PHONE_PLACEHOLDER}
                mask={PHONE_MASK}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="email"
                type="email"
                label={gettext('Contact Email')}
                placeholder={gettext('Contact Email')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                name="postal_code"
                label={gettext('Postal Code')}
                placeholder={gettext('Code')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" mt={5}>
            <Button type="button" variant="outlined" onClick={onClose}>
              {gettext('Cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ ml: 1.5 }}
              disabled={isSubmitting}
            >
              {id ? gettext('Save') : gettext('Create')}
            </Button>
          </Grid>
        </Box>
      )}
    </Formik>
  )
}
