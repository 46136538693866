import {
  ComponentProps,
  ComponentType,
  MouseEvent,
  ReactNode,
  useState,
} from 'react'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

type ActionDividerType = {
  type: 'divider'
  title?: never
  icon?: never
  wrapper?: never
  wrapperProps?: never
  onClick?: never
}

export type ActionMenuItemType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ComponentType<any> = ComponentType<any>,
> =
  | ActionDividerType
  | {
      type?: never
      title: ReactNode
      icon?: ReactNode
      wrapper?: T
      wrapperProps?: ComponentProps<T>
      onClick?: () => void
    }

type Props = {
  items: ActionMenuItemType[]
  className?: string
}

export default function ActionsMenu({ items, className }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event?: object | MouseEvent<HTMLElement>) => {
    if (event && 'preventDefault' in event) {
      event.preventDefault()
    }

    if (event && 'stopPropagation' in event) {
      event.stopPropagation()
    }

    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        className={className}
        sx={{
          '&&': {
            opacity: anchorEl ? 1 : undefined,
          },
        }}
        onClick={handleClick}
      >
        <MoreHorizIcon color="action" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        {items.map(
          (
            { type, title, icon, wrapper: Wrapper, wrapperProps, onClick },
            index
          ) => {
            if (type === 'divider') {
              return <Divider key={index} />
            }

            const menuItem = (
              <MenuItem key={index} sx={{ gap: 4 }} onClick={onClick}>
                {icon}
                {title}
              </MenuItem>
            )

            if (Wrapper) {
              return (
                <Wrapper
                  key={index}
                  {...wrapperProps}
                  onClose={() => {
                    wrapperProps?.onClose?.()
                    handleClose()
                  }}
                >
                  {menuItem}
                </Wrapper>
              )
            }

            return menuItem
          }
        )}
      </Menu>
    </>
  )
}
