import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type Props = {
  label: ReactNode
  content: ReactNode
}

export default function LabeledText({ label, content }: Props) {
  return (
    <Box>
      <Typography
        component="div"
        variant="caption"
        fontWeight={500}
        color="action.active"
      >
        {label}
      </Typography>
      <Typography component="div" variant="body2">
        {content}
      </Typography>
    </Box>
  )
}
