import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Field, Formik, Form, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useAuth } from 'common/services/auth'
import useFormSubmit from 'common/hooks/useFormSubmit'
import { useTranslations } from 'common/services/translations'

import getValidationSchema from './validationSchema'

type LoginValues = {
  email: string
  password: string
}

const initialValues: LoginValues = {
  email: '',
  password: '',
}

export default function Login() {
  const { gettext } = useTranslations()
  const { refetchUser } = useAuth()
  const validationSchema = useMemo(
    () => getValidationSchema(gettext),
    [gettext]
  )
  const { generalError, submitForm } =
    useFormSubmit<LoginValues>('accounts/login')

  const handleSubmit = useCallback(
    (data: LoginValues, actions: FormikHelpers<LoginValues>) => {
      return submitForm({ data, actions, onSuccess: refetchUser })
    },
    [submitForm, refetchUser]
  )

  return (
    <>
      {generalError && (
        <Alert severity="error" color="error" sx={{ mb: 2 }}>
          <AlertTitle>{gettext('Error')}</AlertTitle>
          {generalError}
        </Alert>
      )}
      <Typography variant="h5" fontWeight={600} mb={4}>
        {gettext('Login to Elixir')}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={TextField}
              name="email"
              type="email"
              label={gettext('Email address')}
              placeholder="email@example.com"
              sx={{ width: '100%', mb: 4 }}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              component={TextField}
              name="password"
              type="password"
              label={gettext('Password')}
              placeholder={gettext('Your password')}
              sx={{ width: '100%', mb: 4 }}
              InputLabelProps={{ shrink: true }}
            />
            <Box mb={3} pr={1.5} textAlign="right">
              <Typography
                component={Link}
                to="/auth/forgot-password"
                variant="subtitle2"
                color="secondary.light"
                fontWeight={600}
              >
                {gettext('Forgot password?')}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {gettext('Login')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
