import { useCallback, useMemo } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Field, Formik, Form, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import useFormSubmit from 'common/hooks/useFormSubmit'
import { useTranslations } from 'common/services/translations'

import getValidationSchema from './validationSchema'

type CreateValues = {
  password: string
  password_confirm: string
  signature?: string
}

const initialValues: CreateValues = {
  password: '',
  password_confirm: '',
}

export default function CreatePassword() {
  const { gettext } = useTranslations()
  const { signature } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = useMemo(
    () => getValidationSchema(gettext),
    [gettext]
  )
  const { generalError, submitForm } = useFormSubmit<CreateValues>(
    'accounts/password/confirm'
  )

  const handleSubmit = useCallback(
    (data: CreateValues, actions: FormikHelpers<CreateValues>) => {
      const onSuccess = () => {
        enqueueSnackbar(gettext('Password was changed successfully'), {
          variant: 'success',
        })
        navigate('/auth/login')
      }

      return submitForm({
        data: {
          ...data,
          signature,
        },
        actions,
        onSuccess,
      })
    },
    [enqueueSnackbar, gettext, navigate, submitForm, signature]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors }) => (
        <>
          {(generalError || errors.signature) && (
            <Alert severity="error" color="error" sx={{ mb: 2 }}>
              <AlertTitle>{gettext('Error')}</AlertTitle>
              {generalError || errors.signature}
            </Alert>
          )}
          <Typography variant="h5" fontWeight={600} mb={2}>
            {gettext('Create New Password')}
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={4}>
            {gettext(
              'Notice: your new password must be different from previous used password.'
            )}
          </Typography>
          <Form>
            <Field
              component={TextField}
              name="password"
              type="password"
              label={gettext('Create Password')}
              placeholder={gettext('Your password')}
              helperText={gettext('Minimum 8 characters')}
              sx={{ width: '100%', mb: 4 }}
              InputLabelProps={{ shrink: true }}
            />
            <Field
              component={TextField}
              name="password_confirm"
              type="password"
              label={gettext('Confirm Password')}
              placeholder={gettext('Confirm password')}
              sx={{ width: '100%', mb: 3 }}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              {gettext('Create New Password')}
            </Button>
            <Box mt={3}>
              <Typography
                component={Link}
                to="/auth/login"
                variant="subtitle2"
                color="secondary.light"
                fontWeight={600}
              >
                {gettext('Login')}
              </Typography>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  )
}
