import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CakeIcon from '@mui/icons-material/Cake'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import { SxProps } from '@mui/material/styles'
import dayjs from 'dayjs'

import { PatientResponse } from 'types'
import Status from 'common/components/request/Status'
import { useChangePatientStatus } from 'common/api/patient'
import useChoices from 'common/hooks/useChoices'
import { interpolate, useTranslations } from 'common/services/translations'
import formatNHS from 'common/utils/formatters/formatNHS'
import formatPhoneNumber from 'common/utils/formatters/formatPhoneNumber'
import ModalTrigger from 'common/components/ModalTrigger'
import PatientForm from 'common/components/patient/Form'
import { ProgressIcon } from 'common/components/SvgIcons'

import calculateAge from './calculateAge'
import getAgeBackground from './getAgeBackground'
import Labels from '../Labels'

type Props = {
  patient: PatientResponse
  sx?: SxProps
  withAgeBackground?: boolean
  withoutLabels?: boolean
  refetch: () => void
}

export default function Details({
  patient,
  sx,
  withAgeBackground,
  withoutLabels,
  refetch,
}: Props) {
  const { gettext, ngettext } = useTranslations()
  const options = useChoices('PatientPID.guardian_relationship')
  const relationship = patient.guardian_relationship
    ? options.find(({ value }) => value === patient.guardian_relationship)
        ?.label
    : ''

  const { mutateAsync: changePatientStatus, isPending } =
    useChangePatientStatus(refetch)

  return (
    <Paper
      sx={{
        p: 4.5,
        backgroundColor: withAgeBackground
          ? getAgeBackground(patient.date_of_birth)
          : undefined,
        ...sx,
      }}
    >
      <Stack
        gap={1}
        justifyContent="space-between"
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Box maxWidth={{ sm: '75%' }}>
          <Stack
            direction="row"
            justifyContent="start"
            columnGap={1.5}
            rowGap={0.5}
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          >
            <Typography
              variant="body1"
              color="primary.light"
              fontWeight={600}
              maxWidth="80%"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {patient.name}
            </Typography>
            <Status type="patient" status={patient.status} />
            <Stack direction="row" alignItems="flex-start">
              <ModalTrigger
                title={gettext('Edit Patient Details')}
                description={gettext('Add patient’s details.')}
                component={PatientForm}
                componentProps={{
                  id: patient.uuid,
                  refetch,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<EditIcon />}
                  sx={{ minWidth: 'unset', '.MuiButton-startIcon': { m: 0 } }}
                />
              </ModalTrigger>
              <ModalTrigger
                title={interpolate(
                  gettext('Mark %s’s participation in the study as %s?'),
                  [
                    patient.name,
                    patient.status === 'in_progress'
                      ? gettext('completed')
                      : gettext('in progress'),
                  ]
                )}
                description={
                  patient.status === 'in_progress'
                    ? gettext(
                        'That means he has filled out all necessary forms and has all required labels.'
                      )
                    : gettext(
                        'This indicates that the study has not yet been completed for this patient.'
                      )
                }
                actions={{
                  cancelButtonProps: {
                    children: gettext('Cancel'),
                  },
                  submitButtonProps: {
                    children: interpolate(gettext('Mark As %s'), [
                      patient.status === 'in_progress'
                        ? gettext('completed')
                        : gettext('in progress'),
                    ]),
                    disabled: isPending,
                    onClick: () => changePatientStatus(patient),
                  },
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={
                    patient.status === 'in_progress' ? (
                      <CheckIcon />
                    ) : (
                      <ProgressIcon stroke="currentColor" />
                    )
                  }
                  sx={{
                    ml: 0.5,
                    minWidth: 'unset',
                    '.MuiButton-startIcon': { m: 0 },
                  }}
                />
              </ModalTrigger>
            </Stack>
          </Stack>
          <Typography variant="body2" color="text.disabled" noWrap mb={1}>
            {interpolate(
              ngettext(
                '%s request',
                '%s requests',
                patient.number_of_requests || 0
              ),
              [patient.number_of_requests || 0]
            )}
          </Typography>
          <Typography
            variant="caption"
            color="text.disabled"
            fontWeight={500}
            mb={0.25}
          >
            {gettext('Guardian')}
          </Typography>
          <Typography variant="body2" noWrap mb={0.25}>
            {patient.guardian_first_name} {patient.guardian_last_name}{' '}
            {relationship ? `(${relationship})` : ''}
          </Typography>
          <Typography variant="body2" noWrap mb={0.25}>
            {formatPhoneNumber(patient.contact_number)}
          </Typography>
          <Typography variant="body2" noWrap>
            {patient.email}
          </Typography>
        </Box>
        <Box textAlign={{ xs: 'left', sm: 'right' }}>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
            mb={1}
          >
            <CakeIcon fontSize="small" color="disabled" sx={{ mr: 1 }} />
            <Typography variant="body2" textAlign="right" noWrap>
              {dayjs(patient.date_of_birth).format('D MMMM, YYYY')}
            </Typography>
          </Grid>
          <Typography variant="body2" mb={1}>
            {patient.date_of_birth &&
              calculateAge(patient.date_of_birth, ngettext)}
          </Typography>
          <Typography variant="body2">
            <strong>{gettext('NHS#')}</strong> {formatNHS(patient?.nhs)}
          </Typography>
        </Box>
      </Stack>
      {!withoutLabels && !!patient.labels?.length && (
        <Box mt={1.5}>
          <Labels labels={patient.labels} />
        </Box>
      )}
    </Paper>
  )
}
