import ReactDOM from 'react-dom/client'
import { init } from '@sentry/react'

import 'assets/styles/main.scss'

import App from './App'

if (import.meta.env.SENTRY_DSN) {
  init({
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.SENTRY_ENVIRONMENT,
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
