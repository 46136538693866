import ContactSection from 'layouts/PublicLayout/ContactSection'
import FAQSection from 'layouts/PublicLayout/FAQSection'

import AboutSection from './AboutSection'
import HeroSection from './HeroSection'
import HowSection from './HowSection'

export default function Home() {
  return (
    <>
      <HeroSection />
      <HowSection />
      <AboutSection />
      <FAQSection />
      <ContactSection />
    </>
  )
}
