import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useAuth } from 'common/services/auth'
import { PaginationResponse, PatientResponse } from 'types'

type Variables = {
  search?: string
  limit?: number
  study?: string[]
}
type QueryKey = ['patients', Variables]
type Options = Omit<
  UseQueryOptions<
    PaginationResponse<PatientResponse>,
    DefaultError,
    PatientResponse[],
    QueryKey
  >,
  'queryKey' | 'queryFn'
>

const usePatientList = (variables: Variables = {}, options: Options = {}) => {
  const { currentStudy } = useAuth()
  const defaultVariables: Variables = {
    limit: 10,
    study: currentStudy ? [currentStudy.uuid] : undefined,
  }
  return useQuery<
    PaginationResponse<PatientResponse>,
    DefaultError,
    PatientResponse[],
    QueryKey
  >({
    queryKey: ['patients', { ...defaultVariables, ...variables }],
    select: (data) => data.results,
    ...options,
  })
}

export default usePatientList
