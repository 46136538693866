import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CancelIcon from '@mui/icons-material/Cancel'
import DiscountIcon from '@mui/icons-material/Discount'

import {
  PaginationResponse,
  PatientResponse,
  RequestType,
  ResponseType,
} from 'types'
import { access, CheckAccess } from 'common/access'
import { useAuth } from 'common/services/auth'
import { interpolate, useTranslations } from 'common/services/translations'
import { LabelDetails } from 'common/components/label'
import Details from 'common/components/patient/Details'
import ModalTrigger from 'common/components/ModalTrigger'
import LabelForm from 'common/components/patient/LabelForm'
import RequestForm from 'common/components/request/Form'
import variables from 'assets/styles/_variables.module.scss'

import RequestBlock from './RequestBlock'
import styles from './styles'
import { useRemovePatientLabel } from 'common/api/label'

export default function PatientDetails() {
  const { id } = useParams()
  const { currentStudy } = useAuth()
  const { gettext } = useTranslations()
  const navigate = useNavigate()

  const {
    data: patient,
    isLoading,
    refetch: refetchPatient,
  } = useQuery<PatientResponse>({ queryKey: [`patients/${id}`] })

  const { data: requests, refetch: refetchRequests } = useQuery<
    PaginationResponse<RequestType>,
    null,
    RequestType[]
  >({
    queryKey: ['patients/requests', { patient: id, ordering: '-created' }],
    select: (data) => data.results,
  })
  const { data: responses } = useQuery<
    PaginationResponse<ResponseType>,
    null,
    Record<string, ResponseType>
  >({
    queryKey: ['patients/responses', { patient: id }],
    select: (data) =>
      data.results.reduce(
        (obj, item) => ({
          ...obj,
          [item.uuid]: item,
        }),
        {}
      ),
  })

  const { mutateAsync: removePatientLabel, isPending } = useRemovePatientLabel(
    id!
  )

  const refetchAll = useCallback(() => {
    refetchPatient()
    refetchRequests()
  }, [refetchPatient, refetchRequests])

  useEffect(() => {
    if (patient && currentStudy?.uuid !== patient?.study?.uuid) {
      navigate('/app/patients')
    }
  }, [currentStudy?.uuid, patient, navigate])

  if (isLoading || !patient) {
    return (
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Stack direction="row" gap={4} flexWrap="wrap">
      <Stack
        gap={1.5}
        flexGrow={3}
        flexBasis={{ xs: '100%', sm: '50%' }}
        maxWidth={{ xs: '100%', md: '60%', lg: '75%' }}
      >
        <Stack direction="row" gap={3} alignItems="center">
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => navigate(-1)}
          >
            {gettext('Back')}
          </Button>
          <CheckAccess access={access.F_STUDY_ADMIN}>
            <Typography
              fontSize={14}
              fontWeight={600}
              color={variables.primary50P}
            >
              {patient.hospital.title}
            </Typography>
          </CheckAccess>
        </Stack>
        <Details patient={patient} refetch={refetchPatient} withoutLabels />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pt={1.5}
        >
          <Typography variant="subtitle1" fontWeight={600}>
            {gettext('Requests')}
          </Typography>
          <ModalTrigger
            title={gettext('New Request')}
            component={RequestForm}
            componentProps={{
              initialValues: {
                patient: patient.uuid,
              },
              refetch: refetchAll,
            }}
          >
            <Button variant="contained" startIcon={<AddIcon />}>
              {gettext('New Request')}
            </Button>
          </ModalTrigger>
        </Stack>
        {!requests?.length && (
          <Paper sx={styles.request}>
            <Typography
              variant="subtitle2"
              color="text.disabled"
              fontWeight={600}
            >
              {gettext('No Requests here yet')}
            </Typography>
          </Paper>
        )}
        {requests?.map((request) => (
          <RequestBlock
            key={request.uuid}
            request={request}
            response={
              request.response ? responses?.[request.response] : undefined
            }
            refetch={refetchRequests}
          />
        ))}
      </Stack>
      <Stack gap={1.5} flexBasis="30%" flexGrow={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" fontWeight={600}>
            {gettext('Patient Labels')}
          </Typography>
          <ModalTrigger
            title={interpolate(gettext('Add label for patient %s'), [
              patient.name,
            ])}
            description={gettext(
              'Add a label that is suitable for this patient.'
            )}
            component={LabelForm}
            componentProps={{
              initialValues: {
                patient: patient.uuid,
              },
              refetch: refetchAll,
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ textWrap: 'nowrap' }}
            >
              {gettext('Add Label')}
            </Button>
          </ModalTrigger>
        </Stack>
        {!patient.labels?.length && (
          <Paper sx={styles.request}>
            <Typography
              variant="subtitle2"
              color="text.disabled"
              fontWeight={600}
            >
              {gettext('No Labels here yet')}
            </Typography>
          </Paper>
        )}
        {patient.labels?.map((label) => (
          <LabelDetails
            key={label.created}
            label={label}
            actions={[
              {
                title: gettext('Edit Label'),
                icon: <DiscountIcon sx={styles.actionIcon} />,
                wrapper: ModalTrigger,
                wrapperProps: {
                  title: interpolate(gettext('Edit label for patient %s'), [
                    patient.name,
                  ]),
                  description: gettext(
                    'Edit a label that is suitable for this patient.'
                  ),
                  component: LabelForm,
                  componentProps: {
                    initialValues: {
                      patient: patient.uuid,
                      label_type: label.label_type.uuid,
                      attributes: label.attributes,
                    },
                    id: label.uuid,
                    refetch: refetchPatient,
                  },
                },
              },
              {
                title: gettext('Remove Label'),
                icon: <CancelIcon sx={styles.actionIcon} />,
                wrapper: ModalTrigger,
                wrapperProps: {
                  title: interpolate(gettext('Remove label from patient %s'), [
                    patient.name,
                  ]),
                  description: interpolate(
                    gettext(
                      'Are you sure you want to remove the label from patient %s? '
                    ),
                    [patient.name]
                  ),
                  actions: {
                    cancelButtonProps: {
                      children: gettext('Cancel'),
                    },
                    submitButtonProps: {
                      children: gettext('Remove Label'),
                      disabled: isPending,
                      onClick: () => removePatientLabel(label.uuid),
                    },
                  },
                },
              },
            ]}
          />
        ))}
      </Stack>
    </Stack>
  )
}
