import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { AnswerType } from 'types'

import styles from './styles'
import Preview from '../Preview'

type Props = {
  answer: AnswerType
  withoutPhotoswipe?: boolean
}

export default function Item({ answer, withoutPhotoswipe }: Props) {
  return (
    <Grid
      container
      alignItems="center"
      gap={1}
      py={2}
      px={1.5}
      minHeight={72}
      sx={styles.wrapper}
    >
      <div>
        <Typography variant="caption" fontWeight={500} color="action.active">
          {answer.question_title}
        </Typography>
        {(answer.text || Boolean(answer.selected_options?.length)) && (
          <Typography variant="body2" whiteSpace="pre-wrap">
            {answer.text || (answer.selected_options || []).join(', ')}
          </Typography>
        )}
      </div>
      <Grid container gap={1} width="auto" ml="auto">
        {answer?.attachments?.map((attachment) => (
          <Preview
            key={attachment}
            attachment={attachment}
            type={answer.answer_type}
            withoutPhotoswipe={withoutPhotoswipe}
          />
        ))}
      </Grid>
    </Grid>
  )
}
