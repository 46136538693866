import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import api from 'common/services/api'
import { useTranslations } from 'common/services/translations'

const useRemovePatientLabel = (patientID: string) => {
  const queryClient = useQueryClient()
  const { gettext } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation({
    mutationFn: (id: string) => api.delete(`patients/labels/${id}`),
    onSuccess: () => {
      enqueueSnackbar(gettext('Label deleted successfully'), {
        variant: 'success',
      })
      queryClient.invalidateQueries({ queryKey: [`patients/${patientID}`] })
    },
    onError: () => {
      enqueueSnackbar(gettext('Failed to delete label'), {
        variant: 'error',
      })
    },
  })
}

export default useRemovePatientLabel
