import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import dayjs, { Dayjs } from 'dayjs'

type Props = {
  value?: string
  name: string
  label: string
  onChange: (props: { [k: string]: string }) => void
}

export default function RequestDatePicker({
  value,
  name,
  label,
  onChange,
}: Props) {
  const handleChange = (date: Dayjs | null) => {
    onChange({ [name]: dayjs(date).format('YYYY-MM-DD') })
  }

  return (
    <DesktopDatePicker
      value={value ? dayjs(value) : null}
      views={['year', 'month', 'day']}
      slots={{
        openPickerIcon: () => (
          <>
            <Typography
              fontSize="0.8125rem"
              lineHeight={1.38}
              fontWeight={500}
              color="primary.main"
            >
              {label}
              {value && ': ' + dayjs(value).format('D MMM, YYYY')}
            </Typography>
            <KeyboardArrowDownIcon color="primary" />
          </>
        ),
      }}
      slotProps={{
        textField: {
          sx: {
            input: {
              width: 0,
              pl: 0,
            },
            '.MuiInputAdornment-root': {
              ml: 0,
              '.MuiButtonBase-root:hover': {
                backgroundColor: 'unset',
              },
            },
          },
        },
      }}
      onChange={handleChange}
    />
  )
}
