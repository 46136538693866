import * as Yup from 'yup'
import { TextFieldProps } from '@mui/material/TextField'
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
  TextField,
} from 'formik-mui'

import DatePicker, { DatePickerProps } from 'common/components/DatePicker'
import {
  AttributeValue,
  LabelFieldType,
  LabelType,
  LabelTypeSchema,
} from 'types'

import { FormValues } from '../patient/LabelForm/types'

export const fieldInitialValue: Record<LabelFieldType, AttributeValue> = {
  int: '',
  float: '',
  str: '',
  bool: false,
  date: null,
}

export function getFieldsInitialValues(schema?: LabelTypeSchema[]) {
  return (schema || []).reduce<Pick<FormValues, 'attributes'>>(
    (acc, field) => {
      acc.attributes[field.field_key] = fieldInitialValue[field.field_type]
      return acc
    },
    { attributes: {} }
  )
}

type LabelFieldComponent = {
  int: typeof TextField
  float: typeof TextField
  str: typeof TextField
  bool: typeof CheckboxWithLabel
  date: typeof DatePicker
}

export const fieldsMapper: Record<
  LabelFieldType,
  LabelFieldComponent[LabelFieldType]
> = {
  int: TextField,
  float: TextField,
  str: TextField,
  bool: CheckboxWithLabel,
  date: DatePicker,
}

type LabelFieldTypeProps = {
  int: TextFieldProps
  float: TextFieldProps
  str: TextFieldProps
  bool: CheckboxWithLabelProps
  date: DatePickerProps
}

export const fieldsPropsMapper: Record<
  LabelFieldType,
  | LabelFieldTypeProps[LabelFieldType]
  | ((label: string) => LabelFieldTypeProps[LabelFieldType])
> = {
  int: {
    type: 'number',
    sx: { width: '100%' },
    InputLabelProps: { shrink: true },
  },
  float: {
    type: 'number',
    sx: { width: '100%' },
    InputLabelProps: { shrink: true },
  },
  str: {
    sx: { width: '100%' },
    InputLabelProps: { shrink: true },
  },
  bool: (label) => ({ type: 'checkbox', Label: { label } }),
  date: {
    sx: { width: '100%' },
    views: ['year', 'month', 'day'],
    changeFormat: 'YYYY-MM-DD',
  },
}

const fieldValidationMap: Record<LabelFieldType, Yup.Schema> = {
  int: Yup.number().integer('Must be an integer'),
  float: Yup.number(),
  str: Yup.string(),
  bool: Yup.boolean(),
  date: Yup.date(),
}

export function getAttributesValidationSchema(
  gettext: (text: string) => string,
  schema?: LabelType['schema']
) {
  const requiredMessage = gettext('Required field')

  const attributes = Yup.object(
    (schema || []).reduce<Record<string, Yup.Schema>>((acc, field) => {
      if (field.field_type !== 'bool') {
        acc[field.field_key] =
          fieldValidationMap[field.field_type].required(requiredMessage)
      }
      return acc
    }, {})
  )

  return attributes
}
