import {
  ComponentProps,
  ComponentType,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import styles from './styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ModalWrapperProps<T extends ComponentType<any>> = {
  component?: T
  componentProps?: Omit<ComponentProps<T>, 'onClose'>
  open: boolean
  title: ReactNode
  description?: ReactNode
  dialogProps?: Partial<DialogProps>
  actions?: {
    cancelButtonProps: ButtonProps
    submitButtonProps: ButtonProps
  }
  onClose: () => void
}

const handleKeyDown = (e: KeyboardEvent) => {
  e.stopPropagation()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ModalWrapper<T extends ComponentType<any>>({
  component: ModalComponent,
  componentProps,
  open,
  title,
  description,
  dialogProps,
  actions,
  onClose,
}: ModalWrapperProps<T>) {
  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    Promise.resolve(actions?.submitButtonProps?.onClick?.(e)).then(() =>
      onClose()
    )
  }

  return (
    <Dialog
      {...dialogProps}
      PaperProps={{
        sx: styles.dialog,
      }}
      open={open}
      onClose={onClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle sx={styles.title}>{title}</DialogTitle>
      <DialogContent sx={styles.content}>
        {description && (
          <DialogContentText color="text.primary">
            {description}
          </DialogContentText>
        )}
        {ModalComponent && (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <ModalComponent {...(componentProps as any)} onClose={onClose} />
        )}
      </DialogContent>
      {actions && (
        <DialogActions>
          <Button
            variant="outlined"
            {...actions.cancelButtonProps}
            onClick={onClose}
          />
          <Button
            variant="contained"
            {...actions.submitButtonProps}
            onClick={onSubmit}
          />
        </DialogActions>
      )}
    </Dialog>
  )
}
