import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useTranslations } from 'common/services/translations'

import styles from './Contact.module.scss'

export default function Contact() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.wrapper}>
      <Stack spacing={4} alignItems="center" className={styles.content}>
        <Typography
          variant="h3"
          color="primary.dark"
          fontWeight={800}
          textAlign="center"
        >
          {gettext('Contact Us')}
        </Typography>
        <Typography variant="body2" color="primary.dark" textAlign="center">
          {gettext(
            "We are here to help! If you have any questions, need support, or want more information about our studies, please don't hesitate to reach out to us."
          )}
        </Typography>
        <Typography variant="body2" color="primary.dark" textAlign="center">
          {gettext(
            'If your question is about a specific study, please include the study name within your message.'
          )}
        </Typography>
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 600, p: 4 }}>
          <Stack spacing={1} alignItems="center">
            <Typography
              variant="subtitle1"
              color="primary.dark"
              fontWeight={600}
            >
              {gettext('Email Us')}
            </Typography>
            <Typography variant="body2" color="primary.main">
              info@elixir.ai
            </Typography>
            <Button
              href="mailto:info@elixir.ai"
              variant="contained"
              size="large"
            >
              {gettext('Click here to send an Email')}
            </Button>
          </Stack>
        </Paper>
        <Typography variant="body1" color="primary.dark" textAlign="center">
          {gettext('We look forward to assisting you.')}
        </Typography>
      </Stack>
    </div>
  )
}
