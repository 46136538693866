import { lighten } from '@mui/material/styles'

import variables from 'assets/styles/_variables.module.scss'

const styles = {
  wrapper: {
    backgroundColor: lighten(variables.primaryMain, 0.96),
    borderRadius: 1,
  },
}

export default styles
