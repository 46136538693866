import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import { interpolate, useTranslations } from 'common/services/translations'
import textLogo from 'assets/img/text-logo.svg'

import styles from './PublicLayout.module.scss'

export default function Footer() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Stack alignItems="flex-start" spacing={2}>
              <img src={textLogo} alt="Elixir AI" height={24} />
              <Typography
                component="a"
                href="mailto:info@elixir.ai"
                variant="body2"
                color="primary.light"
              >
                info@elixir.ai
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={2}>
              <Typography
                component={Link}
                to="/"
                variant="body2"
                color="primary.light"
              >
                {gettext('Home')}
              </Typography>
              <Typography
                component={Link}
                to="/studies"
                variant="body2"
                color="primary.light"
              >
                {gettext('Studies')}
              </Typography>
              <Typography
                component={Link}
                to="/contact"
                variant="body2"
                color="primary.light"
              >
                {gettext('Contact Us')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={2}>
              <Typography
                component={Link}
                to="/terms-and-conditions"
                variant="body2"
                color="primary.light"
              >
                {gettext('Terms & Conditions')}
              </Typography>
              <Typography
                component={Link}
                to="/privacy-policy"
                variant="body2"
                color="primary.light"
              >
                {gettext('Privacy Policy')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="body2" mt={3}>
          {interpolate(gettext('ⓒ Elixir AI, %s'), [dayjs().year()])}
        </Typography>
      </div>
    </div>
  )
}
