import { useMemo, useState } from 'react'
import * as Yup from 'yup'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { Field, Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'

import { useLabelTypes } from 'common/api/label'
import {
  LabelFields,
  getFieldsInitialValues,
  getAttributesValidationSchema,
} from 'common/components/label'
import SelectField from 'common/components/SelectField'
import usePrevious from 'common/hooks/usePrevious'
import useFormSubmit from 'common/hooks/useFormSubmit'
import { interpolate, useTranslations } from 'common/services/translations'

import { FormValues } from './types'

const defaultValues: FormValues = {
  patient: '',
  label_type: '',
  attributes: {},
  isAddAnother: false,
}

type LabelFormProps = {
  initialValues?: Partial<FormValues> & Pick<FormValues, 'patient'>
  id?: string
  onClose: () => void
  refetch: () => void
}

export default function LabelForm({
  initialValues = defaultValues,
  id,
  onClose,
  refetch,
}: LabelFormProps) {
  const [labelType, setLabelType] = useState<string>(
    initialValues.label_type || ''
  )
  const previousLabelType = usePrevious(labelType)
  const { gettext } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const { data: labels = [] } = useLabelTypes({
    patient: initialValues.patient,
  })

  const isEdit = !!id
  const schema = labels.find(({ uuid }) => uuid === labelType)?.schema
  const validationSchema = useMemo(
    () =>
      Yup.object({
        patient: Yup.string().required(gettext('Choose patient')),
        label_type: Yup.string().required(gettext('Required field')),
        attributes: getAttributesValidationSchema(gettext, schema),
      }),
    [gettext, schema]
  )

  const { generalError: errorCreate, submitForm: submitCreate } = useFormSubmit<
    FormValues,
    { id: string },
    Omit<FormValues, 'isAddAnother'>
  >('patients/labels')
  const { generalError: errorUpdate, submitForm: submitUpdate } = useFormSubmit<
    FormValues,
    { id: string },
    Pick<FormValues, 'attributes'>
  >(`patients/labels/${id}`, 'patch')

  const generalError = errorCreate || errorUpdate

  const handleSubmit = (
    data: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    const onSuccess = () => {
      const labelName = find(labels, { uuid: data.label_type })!.name

      enqueueSnackbar(
        interpolate(
          isEdit
            ? gettext('Label "%s" updated successfully')
            : gettext('Label "%s" added successfully'),
          [labelName]
        ),
        { variant: 'success' }
      )
      refetch()

      if (data.isAddAnother) {
        actions.resetForm()
      } else {
        onClose()
      }
    }

    const request = isEdit ? submitUpdate : submitCreate
    request({
      data: isEdit
        ? ({ attributes: data.attributes } as FormValues)
        : {
            patient: data.patient,
            label_type: data.label_type,
            attributes: data.attributes,
          },
      actions,
      onSuccess,
    })
  }

  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ...initialValues,
        label_type: labelType,
        ...(isEdit ? {} : getFieldsInitialValues(schema)),
      }}
      validateOnMount
      enableReinitialize={previousLabelType !== labelType}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, setFieldValue, submitForm }) => (
        <Box component={FormikForm} mt={3}>
          {generalError && (
            <Alert severity="error" color="error" sx={{ mb: 2 }}>
              <AlertTitle>{gettext('Error')}</AlertTitle>
              {generalError}
            </Alert>
          )}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field
                component={SelectField}
                options={labels}
                name="label_type"
                valueKey="uuid"
                labelKey="name"
                label={gettext('Select Label')}
                placeholder={gettext('Select the label')}
                formControl={{ sx: { width: '100%' } }}
                inputLabel={{ shrink: true }}
                notched
                displayEmpty
                disabled={isEdit}
                onChange={(e: SelectChangeEvent) =>
                  setLabelType(e.target.value)
                }
              />
            </Grid>
            <LabelFields schema={schema} />
          </Grid>
          <Stack direction="row" spacing={1.5} justifyContent="flex-end" mt={3}>
            <Button type="button" variant="outlined" onClick={onClose}>
              {gettext('Cancel')}
            </Button>
            {!isEdit && (
              <Button
                type="button"
                variant="outlined"
                disabled={!isValid || isSubmitting}
                onClick={async () => {
                  await setFieldValue('isAddAnother', true)
                  submitForm()
                }}
              >
                {gettext('Save & Add Another')}
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              disabled={!isValid || isSubmitting}
              sx={{ ml: 1.5 }}
            >
              {gettext('Save')}
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  )
}
