import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { useTranslations } from 'common/services/translations'
import { PublicStudyList } from 'types'

import styles from './StudiesList.module.scss'

type ListProps = {
  studies?: PublicStudyList[]
  onClick: (id: string) => void
}

export default function List({ studies, onClick }: ListProps) {
  const { gettext } = useTranslations()

  if (!studies?.length) {
    return null
  }

  return (
    <Stack width="100%" spacing={3}>
      {studies?.slice(0, 3).map((study) => (
        <Box
          key={study.uuid}
          sx={{ p: 3 }}
          className={styles.study}
          onClick={() => onClick(study.uuid)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Typography variant="subtitle1" fontWeight={600} mb={1}>
                {study.name}
              </Typography>
              {study.study_overview?.items?.map((item) => (
                <Typography
                  key={item.name}
                  variant="body2"
                  color="text.secondary"
                >
                  <strong>{item.name}:</strong> {item.text}
                </Typography>
              ))}
            </Grid>
            <Grid item ml={{ xs: 0, md: 'auto' }}>
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIcon className={styles.btn} />}
              >
                {gettext('Learn More')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Stack>
  )
}
