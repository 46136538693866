import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { SxProps } from '@mui/material'

import { PatientResponse } from 'types'
import PatientDetails from 'common/components/patient/Details'

type Props = {
  patientId?: string
  sx?: SxProps
  setFieldValue: (field: string, value: unknown) => void
}

export default function PatientBlock({ patientId, sx, setFieldValue }: Props) {
  const { data: patient, refetch } = useQuery<PatientResponse>({
    queryKey: [`patients/${patientId}`],
    enabled: Boolean(patientId),
  })

  useEffect(() => {
    if (!patient) {
      return
    }

    setFieldValue('patient_details', patient)
    setFieldValue('phone_number', patient.contact_number)
  }, [patient, setFieldValue])

  return (
    patient && (
      <PatientDetails
        patient={patient}
        sx={{ p: 1.5, boxShadow: 'none', ...sx }}
        withAgeBackground
        refetch={refetch}
      />
    )
  )
}
