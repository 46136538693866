import * as Yup from 'yup'

export default function getValidationSchema(gettext: (text: string) => string) {
  return Yup.object().shape({
    email: Yup.string()
      .email(gettext('Email is wrong'))
      .required(gettext('Required field')),
    password: Yup.string().required(gettext('Required field')),
  })
}
