import variables from 'assets/styles/_variables.module.scss'

const styles = {
  request: {
    p: 3,
    borderRadius: 2,
  },
  accordion: {
    mt: 4,
    display: 'flex',
    flexDirection: 'column-reverse',
    '&::before': {
      display: 'none',
    },
  },
  expandIcon: {
    color: 'text.primary',
  },
  summary: {
    minHeight: 'auto',
    p: 0,
    justifyContent: 'flex-start',
    gap: '5px',
    '.MuiAccordionSummary-content': {
      m: 0,
      flexGrow: 0,
      alignItems: 'center',
    },
  },
  details: {
    pt: 0,
    pb: 1.5,
    px: 0,
  },
  actionIcon: {
    color: variables.primary50P,
  },
}

export default styles
