import { lighten, useTheme } from '@mui/material/styles'

import variables from 'assets/styles/_variables.module.scss'

export default function useStyles() {
  const theme = useTheme()

  return {
    autocomplete: {
      width: '100%',
      mt: 2,
      '.MuiAutocomplete-popupIndicator': {
        transform: 'none',
      },
    },
    option: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
    },
    icon: {
      color: 'text.secondary',
    },
    paper: {
      '.MuiAutocomplete-listbox': {
        [theme.breakpoints.down('sm')]: {
          maxHeight: 170,
        },
        py: 0,
      },
    },
    addBtn: {
      p: 2,
      justifyContent: 'flex-start',
      gap: 2,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: 'divider',
    },
    btnWrapper: {
      p: 1,
      display: 'flex',
      borderRadius: '50%',
      backgroundColor: lighten(variables.secondaryMain, 0.88),
    },
    btnIcon: {
      color: 'secondary.light',
    },
  }
}
