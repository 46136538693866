import { ChangeEvent, useEffect, useRef, useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'

type Props = Partial<TextFieldProps> & {
  value?: string
  name: string
  delay?: number
  onChange: (props: { [k: string]: string }) => void
}

export default function SearchInput({
  value = '',
  name,
  delay = 500,
  onChange,
  ...props
}: Props) {
  const [search, setSearch] = useState(value)
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeoutId.current || 0)

    const { value } = event.target
    setSearch(value)

    timeoutId.current = setTimeout(() => {
      onChange({ [name]: value })
    }, delay)
  }

  useEffect(() => () => clearTimeout(timeoutId.current || 0), [])

  useEffect(() => {
    // Reset local value after changing it on parent level
    if (!value) {
      setSearch('')
    }
  }, [value])

  return (
    <TextField
      {...props}
      value={search}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
    />
  )
}
