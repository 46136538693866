const styles = {
  dialog: {
    width: '100%',
    maxWidth: '584px',
  },
  title: {
    pt: 4,
    pb: 1,
    px: 4,
  },
  content: {
    pb: 4,
    px: 4,
  },
}

export default styles
