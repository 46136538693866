import { ReactNode } from 'react'

import AccessType from './access'
import useCheckAccess from './useCheckAccess'

type Props = {
  access: AccessType
  rule?: 'some' | 'every'
  fallback?: ReactNode
  children: ReactNode
}

export default function CheckAccess({
  access,
  rule = 'some',
  fallback,
  children,
}: Props) {
  const hasAccesses = useCheckAccess(access)
  return hasAccesses[rule](Boolean) ? children : fallback
}
