import * as Yup from 'yup'

export default function getValidationSchema(
  gettext: (text: string) => string,
  isResend?: boolean
) {
  return Yup.object().shape({
    patient: Yup.string().required('Choose patient'),
    note: Yup.string(),
    form: Yup.string().required(gettext('Required field')),
    additional_sms_text: Yup.string().max(
      100,
      gettext('Maximum 100 characters')
    ),
    ...(isResend
      ? {
          cancellation_reason: Yup.string().required(gettext('Required field')),
        }
      : {}),
  })
}
