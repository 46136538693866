import Stack from '@mui/material/Stack'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import Footer from './Footer'
import Header from './Header'

export default function PublicLayout() {
  return (
    <Stack sx={{ minHeight: '100vh' }}>
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </Stack>
  )
}
