import { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { find } from 'lodash-es'
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment'
import GroupIcon from '@mui/icons-material/Group'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

import { Study } from 'types'
import { access, CheckAccess } from 'common/access'
import useAnchor from 'common/hooks/useAnchor'
import useChoices from 'common/hooks/useChoices'
import api from 'common/services/api'
import { useAuth } from 'common/services/auth'
import { useTranslations } from 'common/services/translations'
import getUserFirstLetters from 'common/utils/getUserFirstLetters'
import logo from 'assets/img/logo.svg'
import support from 'assets/img/support.svg'
import variables from 'assets/styles/_variables.module.scss'

const routes = ['/app/requests', '/app/patients']

export default function Header() {
  const { config } = useAuth()
  const location = useLocation()
  const roles = useChoices('Role.kind')
  const { gettext } = useTranslations()
  const userMenuAnchor = useAnchor('user-menu')
  const currentStudyAnchor = useAnchor('current-study')
  const { user, removeUser, currentStudy, setStudy } = useAuth()

  const handleLogout = () => {
    api.post('accounts/logout', {}).then(removeUser)
  }

  const selectStudy = useCallback(
    (study: Study) => () => {
      setStudy(study.uuid)
      currentStudyAnchor.onClose()
    },
    [setStudy, currentStudyAnchor]
  )

  const role = find(roles, { value: user?.role })?.label

  const selectCurrentStudy = (
    <>
      <IconButton {...currentStudyAnchor.triggerProps}>
        <KeyboardArrowDown />
      </IconButton>
      <Menu {...currentStudyAnchor.menuProps}>
        {user?.studies.map((study) => (
          <MenuItem
            key={study.uuid}
            onClick={selectStudy(study)}
            selected={currentStudy?.uuid === study.uuid}
          >
            {study.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )

  const userSettings = (
    <Tooltip title={gettext('Open settings')}>
      <IconButton {...userMenuAnchor.triggerProps} sx={{ p: 0 }}>
        <Avatar sx={{ backgroundColor: variables.secondaryLight }}>
          {getUserFirstLetters(user)}
        </Avatar>
      </IconButton>
    </Tooltip>
  )

  return (
    <AppBar position="static" color="inherit" elevation={1}>
      <Toolbar disableGutters sx={{ px: 3, gap: 3 }}>
        <Box component="img" src={logo} width="48px" height="48px" />
        {currentStudy && (
          <Typography
            variant="subtitle1"
            fontWeight={600}
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {currentStudy?.name}
            {selectCurrentStudy}
          </Typography>
        )}
        <Box ml="auto">
          <Stack direction="row" gap={2}>
            {config?.contact_admin_email && (
              <Tooltip title={gettext('Contact to admin')}>
                <IconButton href={`mailto:${config.contact_admin_email}`}>
                  <Box
                    component="img"
                    color="primary.main"
                    src={support}
                    width={30}
                    height={30}
                    alt={gettext('Write email to support admin')}
                  />
                </IconButton>
              </Tooltip>
            )}
            <CheckAccess
              access={access.F_STUDY_ADMIN}
              fallback={
                <Box
                  padding={0.5}
                  border="1px solid"
                  borderColor={variables['secondary-30p']}
                  borderRadius={1}
                >
                  <Stack direction="row" alignItems="center" gap={1.5}>
                    <Typography
                      sx={{ pl: 1 }}
                      color="primary"
                      fontWeight={600}
                      fontSize={14}
                    >
                      {user?.hospital.title}
                    </Typography>
                    {userSettings}
                  </Stack>
                </Box>
              }
            >
              {userSettings}
            </CheckAccess>
            <Menu
              {...userMenuAnchor.menuProps}
              sx={{ mt: 6 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              elevation={8}
            >
              <Stack sx={{ mb: 1.25, mx: 2, py: 0.5 }}>
                <Typography variant="body1">
                  {user?.first_name} {user?.last_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {role}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {user?.email}
                </Typography>
              </Stack>
              <Divider />
              <MenuItem sx={{ mt: 1 }} onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                  {gettext('Log out')}
                </ListItemText>
              </MenuItem>
            </Menu>
          </Stack>
        </Box>
      </Toolbar>
      <Divider />
      <Tabs
        value={
          routes.find((route) => location.pathname.includes(route)) || false
        }
        sx={{ minHeight: 42, mt: 1 }}
      >
        <Tab
          component={Link}
          to={routes[1]}
          value={routes[1]}
          label={gettext('Patients')}
          icon={<GroupIcon />}
          iconPosition="start"
          sx={{ minHeight: 42 }}
        />
        <Tab
          component={Link}
          to={routes[0]}
          value={routes[0]}
          label={gettext('Requests')}
          icon={<AssignmentIcon />}
          iconPosition="start"
          sx={{ minHeight: 42 }}
        />
      </Tabs>
    </AppBar>
  )
}
