import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Hospital, PaginationResponse } from 'types'

type RequestOptions = Pick<
  UseQueryOptions<PaginationResponse<Hospital>, DefaultError, Hospital[]>,
  'enabled'
>

export function useHospitals(options: RequestOptions = {}) {
  return useQuery<PaginationResponse<Hospital>, DefaultError, Hospital[]>({
    queryKey: ['hospitals'],
    select: (data) => data.results,
    ...options,
  })
}
