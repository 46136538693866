import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, NavLink } from 'react-router-dom'

import { access, CheckAccess } from 'common/access'
import { useTranslations } from 'common/services/translations'
import logo from 'assets/img/logo-horizontal.svg'

import styles from './PublicLayout.module.scss'

export default function Header() {
  const [open, setOpen] = useState(false)
  const { gettext } = useTranslations()
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const toggleNavMenu = () => setOpen((open) => !open)

  useEffect(() => {
    setOpen(false)
  }, [isSm])

  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <Box component={Link} to="/" sx={{ display: 'flex' }}>
          <img src={logo} alt="Elixir AI" />
        </Box>
        <Box
          className={styles.menu}
          sx={{ display: { xs: open ? 'flex' : 'none', sm: 'flex' } }}
        >
          <NavLink to="/" className={styles.link} onClick={toggleNavMenu}>
            {gettext('Home')}
          </NavLink>
          <NavLink
            to="/studies"
            className={styles.link}
            onClick={toggleNavMenu}
          >
            {gettext('Studies')}
          </NavLink>
          <NavLink
            to="/contact"
            className={styles.link}
            onClick={toggleNavMenu}
          >
            {gettext('Contact Us')}
          </NavLink>
          <CheckAccess
            access={access.F_PROTECTED}
            fallback={
              <Button
                href="/auth"
                variant="contained"
                sx={{ ml: { sm: 'auto' } }}
                onClick={toggleNavMenu}
              >
                {gettext('Login')}
              </Button>
            }
          >
            <Button
              href="/app"
              variant="contained"
              sx={{ mt: { xs: 5, sm: 0 }, ml: { sm: 'auto' } }}
              onClick={toggleNavMenu}
            >
              {gettext('App')}
            </Button>
          </CheckAccess>
        </Box>
        <IconButton
          size="small"
          sx={{
            ml: 'auto',
            color: 'primary.light',
            display: { xs: 'flex', sm: 'none' },
          }}
          onClick={toggleNavMenu}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
    </div>
  )
}
