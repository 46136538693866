import {
  Children,
  cloneElement,
  ComponentType,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react'

import ModalWrapper, { ModalWrapperProps } from './ModalWrapper'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T extends ComponentType<any>> = Omit<
  ModalWrapperProps<T>,
  'open' | 'onClose'
> & {
  children: ReactElement
  onOpen?: () => void
  onClose?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ModalTrigger<T extends ComponentType<any>>({
  children,
  onOpen,
  onClose,
  ...props
}: Props<T>) {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setOpen(true)
      onOpen?.()
    },
    [onOpen]
  )

  const handleClose = useCallback(() => {
    setOpen(false)
    onClose?.()
  }, [onClose])

  const child = useMemo(() => {
    return cloneElement(Children.only(children), { onClick: handleOpen })
  }, [children, handleOpen])

  return (
    <>
      {child}
      <ModalWrapper {...props} open={open} onClose={handleClose} />
    </>
  )
}
