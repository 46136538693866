import { Chip } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import SendIcon from '@mui/icons-material/Send'

import variables from 'assets/styles/_variables.module.scss'
import { PatientStatus, RequestStatus } from 'types'
import { ProgressIcon } from 'common/components/SvgIcons'
import { getRequestStatus } from 'common/constants/requestStatus'
import { useTranslations } from 'common/services/translations'
import { getPatientStatus } from 'common/constants/patientStatus'

type Props =
  | {
      type: 'patient'
      status: PatientStatus
    }
  | {
      type: 'request'
      status: RequestStatus
    }

type StatusChipConfig = {
  icon: JSX.Element
  bgColor: string
  color: string
}

const requestConfigs: Record<RequestStatus, StatusChipConfig> = {
  complete: {
    icon: <CheckIcon fontSize="small" color="inherit" />,
    bgColor: 'success.light',
    color: 'success.dark',
  },
  expired: {
    icon: <AccessTimeIcon fontSize="small" color="inherit" />,
    bgColor: 'action.hover',
    color: 'action.active',
  },
  review: {
    icon: <RemoveRedEyeIcon fontSize="small" color="inherit" />,
    bgColor: 'warning.light',
    color: 'warning.dark',
  },
  sent: {
    icon: (
      <SendIcon
        fontSize="small"
        sx={{
          fill: variables.infoMain,
          transform: 'translate(2px, -2px) rotate(-40.9deg)',
        }}
      />
    ),
    bgColor: 'info.light',
    color: 'info.dark',
  },
}

const patientConfigs: Record<PatientStatus, StatusChipConfig> = {
  completed: {
    icon: <CheckIcon fontSize="small" color="inherit" />,
    bgColor: 'success.light',
    color: 'success.dark',
  },
  in_progress: {
    icon: <ProgressIcon fontSize="small" stroke="#29B6F6" />,
    bgColor: 'info.light',
    color: 'info.dark',
  },
}

export default function Status({ type, status }: Props) {
  const { gettext } = useTranslations()

  const label = (
    type === 'patient'
      ? getPatientStatus(gettext, status)
      : getRequestStatus(gettext, status)
  )?.label

  const config =
    type === 'patient' ? patientConfigs[status] : requestConfigs[status]

  return (
    <Chip
      label={label}
      icon={config?.icon}
      size="small"
      sx={{
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1,
        borderRadius: 1,
        backgroundColor: config?.bgColor,
        color: config?.color,
      }}
    />
  )
}
