import { SyntheticEvent, useCallback, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import { Field, Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import {
  FormType,
  PaginationResponse,
  PatientResponse,
  RequestType,
} from 'types'
import getIdFromUuid from 'common/utils/getIdFromUuid'
import useFormSubmit from 'common/hooks/useFormSubmit'
import { interpolate, useTranslations } from 'common/services/translations'
import {
  AsyncSelectField,
  ChoicesSelectField,
} from 'common/components/SelectField'

import getValidationSchema from './validationSchema'
import PatientAutocomplete from './PatientAutocomplete'
import PatientBlock from './PatientBlock'

type FormValues = Pick<
  RequestType,
  'patient' | 'form' | 'note' | 'additional_sms_text'
> & {
  patient_details: PatientResponse | null
  cancellation_reason?: string
}

type Props = {
  initialValues?: Partial<FormValues>
  resendId?: string
  onClose: () => void
  refetch: () => void
}

const defaultValues: FormValues = {
  patient: '',
  patient_details: null,
  note: '',
  form: '',
  additional_sms_text: '',
}

export default function Form({
  initialValues = defaultValues,
  resendId,
  onClose,
  refetch,
}: Props) {
  const { gettext } = useTranslations()
  const { enqueueSnackbar } = useSnackbar()

  const isResend = Boolean(resendId)
  const validationSchema = useMemo(
    () => getValidationSchema(gettext, isResend),
    [gettext, isResend]
  )
  const { generalError: createError, submitForm: submitCreate } = useFormSubmit<
    FormValues,
    { id: string }
  >('patients/requests')

  const { generalError: updateError, submitForm: submitUpdate } = useFormSubmit<
    FormValues,
    RequestType
  >(`patients/requests/${resendId}/resend`)

  const handleCreate = useCallback(
    (data: FormValues, actions: FormikHelpers<FormValues>) => {
      const onSuccess = (request: { id: string }) => {
        enqueueSnackbar(
          interpolate(gettext('Request #%s successfully created'), [
            getIdFromUuid(request.id),
          ]),
          { variant: 'success' }
        )
        refetch()
        onClose()
      }

      return submitCreate({
        data,
        actions,
        onSuccess,
      })
    },
    [enqueueSnackbar, gettext, onClose, refetch, submitCreate]
  )

  const handleUpdate = useCallback(
    (data: FormValues, actions: FormikHelpers<FormValues>) => {
      const onSuccess = (request: RequestType) => {
        enqueueSnackbar(
          interpolate(gettext('Request #%s successfully updated'), [
            getIdFromUuid(request.uuid),
          ]),
          { variant: 'success' }
        )
        refetch()
        onClose()
      }

      return submitUpdate({
        data,
        actions,
        onSuccess,
      })
    },
    [enqueueSnackbar, gettext, onClose, refetch, submitUpdate]
  )

  const generalError = isResend ? updateError : createError
  const handleSubmit = isResend ? handleUpdate : handleCreate

  return (
    <Formik
      initialValues={{
        ...defaultValues,
        ...initialValues,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, resetForm, setFieldValue }) => (
        <Box component={FormikForm} mt={2}>
          {generalError && (
            <Alert severity="error" color="error" sx={{ mb: 2 }}>
              <AlertTitle>{gettext('Error')}</AlertTitle>
              {generalError}
            </Alert>
          )}
          {!initialValues?.patient && (
            <Field
              component={PatientAutocomplete}
              name="patient_details"
              onChange={(_: SyntheticEvent, value: PatientResponse | null) => {
                resetForm()
                setFieldValue('patient', value?.uuid || '')
                if (!value) {
                  setFieldValue('patient_details', value)
                }
              }}
              onRefetch={(patientID?: string) => {
                setFieldValue('patient', patientID)
              }}
            />
          )}
          {isResend && (
            <Field
              component={ChoicesSelectField}
              name="cancellation_reason"
              path="Request.cancellation_reason"
              label={gettext('Reason')}
              placeholder={gettext('Select the reason')}
              formControl={{ sx: { width: '100%', mt: 1, mb: 3 } }}
              inputLabel={{ shrink: true }}
              notched
              displayEmpty
            />
          )}
          {Boolean(values.patient) && (
            <>
              <PatientBlock
                patientId={values.patient}
                sx={!initialValues?.patient ? { mt: 3 } : {}}
                setFieldValue={setFieldValue}
              />
              <Grid container alignItems="center" my={3}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  fontWeight={600}
                  mr={2}
                >
                  {isResend
                    ? gettext('New Request Info')
                    : gettext('Request Info')}
                </Typography>
                <Divider sx={{ flexGrow: 1 }} />
              </Grid>
              <Field
                component={AsyncSelectField<FormType>}
                name="form"
                endpoint="forms"
                disabled={isResend}
                queryOptions={{
                  select: (data: PaginationResponse<FormType>) => data.results,
                }}
                params={{ patient: values.patient }}
                valueKey="uuid"
                labelKey="title"
                label={gettext('Request type')}
                placeholder={gettext('Select the type')}
                formControl={{ sx: { width: '100%', mb: 4 } }}
                inputLabel={{ shrink: true }}
                notched
                displayEmpty
              />
              <Field
                component={TextField}
                name="note"
                label={gettext('Note (Optional)')}
                placeholder={gettext('Note')}
                sx={{ width: '100%', mb: 4 }}
                InputLabelProps={{ shrink: true }}
                multiline
              />
              <Field
                component={TextField}
                name="additional_sms_text"
                label={gettext('Additional SMS Text (Optional)')}
                placeholder={gettext('SMS Text')}
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                multiline
                helperText={gettext('Maximum 100 symbols')}
              />
            </>
          )}
          <Grid container justifyContent="flex-end" mt={3}>
            <Button type="button" variant="outlined" onClick={onClose}>
              {gettext('Cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ ml: 1.5 }}
              disabled={!values.patient || isSubmitting}
            >
              {isResend ? gettext('Resend') : gettext('Create')}
            </Button>
          </Grid>
        </Box>
      )}
    </Formik>
  )
}
