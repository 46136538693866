import { createBrowserRouter, Navigate } from 'react-router-dom'

import { access, CheckAccess } from 'common/access'
import AppLayout from 'layouts/AppLayout'
import AuthLayout from 'layouts/AuthLayout'
import PublicLayout from 'layouts/PublicLayout'
import AcceptInvite from 'pages/auth/AcceptInvite'
import CreatePassword from 'pages/auth/CreatePassword'
import ForgotPassword from 'pages/auth/ForgotPassword'
import Login from 'pages/auth/Login'
import Contact from 'pages/Contact'
import Home from 'pages/Home'
import PatientDetails from 'pages/patients/PatientDetails'
import PatientsList from 'pages/patients/PatientsList'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import Requests from 'pages/Requests'
import StudiesList from 'pages/studies/StudiesList'
import StudyDetails from 'pages/studies/StudyDetails'
import TermsAndConditions from 'pages/TermsAndConditions'

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <Navigate to="/" />,
    children: [
      {
        path: '/',
        element: <PublicLayout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'studies',
            element: <StudiesList />,
          },
          {
            path: 'studies/:id',
            element: <StudyDetails />,
          },
          {
            path: 'contact',
            element: <Contact />,
          },
          {
            path: 'privacy-policy',
            element: <PrivacyPolicy />,
          },
          {
            path: 'terms-and-conditions',
            element: <TermsAndConditions />,
          },
        ],
      },
      {
        path: 'app',
        element: (
          <CheckAccess
            access={access.F_PROTECTED}
            fallback={<Navigate to="/" />}
          >
            <AppLayout />
          </CheckAccess>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="patients" />,
          },
          {
            path: 'requests',
            element: <Requests />,
          },
          {
            path: 'patients',
            element: <PatientsList />,
          },
          {
            path: 'patients/:id',
            element: <PatientDetails />,
          },
        ],
      },
      {
        path: 'auth',
        element: (
          <CheckAccess
            access={access.F_UNAUTHORIZED}
            fallback={<Navigate to="/app" />}
          >
            <AuthLayout />
          </CheckAccess>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="login" />,
          },
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: 'create-password/:signature',
            element: <CreatePassword />,
          },
          {
            path: 'accept-invite/:signature',
            element: <AcceptInvite />,
          },
        ],
      },
    ],
  },
])

export default router
