import { useEffect } from 'react'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

import { AUDIO, VIDEO } from 'common/constants/questionTypes'

export default function usePhotoSwipe(id: string) {
  useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      gallery: id,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    })

    lightbox.on('contentLoad', (e) => {
      const { content } = e
      if (!content.data.src) {
        return
      }

      if (content.type === VIDEO) {
        e.preventDefault()
        content.element = document.createElement('div')
        const video = document.createElement('video')
        video.setAttribute('src', content.data.src)
        video.setAttribute('controls', 'true')
        video.setAttribute('preload', 'auto')
        video.setAttribute('width', '100%')
        video.setAttribute('height', '100%')
        content.element.appendChild(video)
      }

      if (content.type === AUDIO) {
        e.preventDefault()
        content.element = document.createElement('div')
        content.element.style.display = 'flex'
        content.element.style.alignItems = 'center'
        content.element.style.justifyContent = 'center'
        const audio = document.createElement('audio')
        audio.setAttribute('src', content.data.src)
        audio.setAttribute('controls', 'true')
        audio.setAttribute('preload', 'auto')
        audio.style.width = '100%'
        content.element.appendChild(audio)
      }
    })

    lightbox.on('contentActivate', (e) => {
      const { content } = e
      if (![AUDIO, VIDEO].includes(content.type)) {
        return
      }

      ;(content.element?.children?.[0] as HTMLMediaElement)?.play()
    })

    lightbox.on('contentDeactivate', (e) => {
      const { content } = e
      if (![AUDIO, VIDEO].includes(content.type)) {
        return
      }

      ;(content.element?.children?.[0] as HTMLMediaElement)?.pause()
    })

    lightbox.init()

    return () => {
      lightbox?.destroy()
      lightbox = null
    }
  }, [id])
}
