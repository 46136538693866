export function getLabelStatuses(gettext: (text: string) => string) {
  return [
    {
      label: gettext('With Labels'),
      value: 'true',
    },
    {
      label: gettext('Without Labels'),
      value: 'false',
    },
  ]
}
