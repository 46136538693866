import { ButtonProps, MenuProps } from '@mui/material'
import { MouseEventHandler, useState } from 'react'

type UseAnchor<TElement extends HTMLElement> = {
  menuProps: Partial<MenuProps> & Pick<MenuProps, 'open'>
  triggerProps: Partial<ButtonProps>
  id: string
  open: boolean
  onOpen: MouseEventHandler<TElement>
  onClose: () => void
  anchorEl: TElement | null
}

/**
 * Custom hook that manages the state and behavior of an anchor element.
 *
 * @template TElement - The type of the anchor element.
 * @param {string} id - The ID of the anchor element.
 * @return {UseAnchor<TElement>} An object containing anchor element properties.
 * @example const menuAnchor = useAnchor('menu');
 * // pass menuProps to Menu
 * const menu = (<Menu {...menuAnchor.menuProps} />)
 * // pass triggerProps to Button
 * const trigger = (<Button {...menuAnchor.triggerProps} />)
 * // if needed, use other properties directly
 * menuAnchor.onOpen()
 */
const useAnchor = <TElement extends HTMLElement>(
  id: string
): UseAnchor<TElement> => {
  const [anchorEl, setAnchorEl] = useState<TElement | null>(null)

  const open = anchorEl !== null

  const onOpen: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget as TElement)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const menuProps = {
    id,
    open,
    onClose,
    anchorEl,
  }

  const triggerProps = {
    onClick: onOpen,
    ['aria-haspopup']: true,
    ['aria-controls']: open ? id : undefined,
    ['aria-expanded']: open ? true : undefined,
  }

  return {
    menuProps,
    triggerProps,
    id,
    open,
    onOpen,
    onClose,
    anchorEl,
  }
}

export default useAnchor
