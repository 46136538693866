const styles = {
  paper: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    overflow: 'hidden',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  headerCell: {
    py: 1,
  },
  row: {
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(232, 236, 248, 0.40) 0%, rgba(232, 236, 248, 0.40) 100%), #fff',
    },
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
  cell: {
    py: '10px',
    whiteSpace: 'nowrap',
  },
  pagination: {
    flexShrink: 0,
    '& .MuiToolbar-root': {
      minHeight: 40,
    },
    '.MuiTablePagination-displayedRows': {
      margin: 0,
    },
  },
}

export default styles
