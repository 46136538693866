import CheckIcon from '@mui/icons-material/Check'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import EditIcon from '@mui/icons-material/Edit'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import dayjs from 'dayjs'

import { PatientResponse, PatientStatus } from 'types'
import ActionsMenu from 'common/components/ActionsMenu'
import { ColumnType } from 'common/components/Table'
import { ProgressIcon } from 'common/components/SvgIcons'
import formatNHS from 'common/utils/formatters/formatNHS'
import Status from 'common/components/request/Status'
import ModalTrigger from 'common/components/ModalTrigger'
import LabelForm from 'common/components/patient/LabelForm'
import Labels from 'common/components/patient/Labels'
import PatientForm from 'common/components/patient/Form'
import RequestForm from 'common/components/request/Form'
import { interpolate } from 'common/services/translations'
import { array } from 'common/utils/array'
import variables from 'assets/styles/_variables.module.scss'

import styles from './PatientsList.module.scss'

export default function getColumns(
  gettext: (text: string) => string,
  refetch: () => void,
  isStudyAdmin: boolean,
  changePatientStatus: (data: {
    uuid: string
    name: string
    status: PatientStatus
  }) => Promise<unknown>,
  isMarkLoading: boolean
) {
  return array<ColumnType<PatientResponse>>(
    {
      id: 'name',
      name: gettext('Name'),
      sorting: true,
      render: (record) => record.name,
    },
    isStudyAdmin && {
      id: 'hospital',
      name: gettext('Hospital'),
      render: (record: PatientResponse) => record.hospital.title,
    },
    {
      id: 'nhs',
      name: gettext('NHS #'),
      sorting: true,
      render: (record) => formatNHS(record?.nhs),
    },
    {
      id: 'date_of_birth',
      name: gettext('DOB'),
      render: (record) => dayjs(record.date_of_birth).format('D MMM, YYYY'),
    },
    {
      id: 'status',
      name: gettext('Status'),
      render: (record) => <Status type="patient" status={record.status} />,
    },
    {
      id: 'labels',
      name: gettext('Labels'),
      render: (record) => <Labels labels={record.labels} maxCount={2} />,
    },
    {
      id: 'actions',
      name: '',
      className: styles.actions,
      render: (record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ActionsMenu
            items={[
              {
                title: gettext('New Request'),
                icon: (
                  <DocumentScannerIcon sx={{ color: variables.primary50P }} />
                ),
                wrapper: ModalTrigger,
                wrapperProps: {
                  title: gettext('New Request'),
                  component: RequestForm,
                  componentProps: {
                    initialValues: {
                      patient: record.uuid,
                    },
                    refetch,
                  },
                },
              },
              {
                title: gettext('Edit Patient Details'),
                icon: <EditIcon sx={{ color: variables.primary50P }} />,
                wrapper: ModalTrigger,
                wrapperProps: {
                  title: gettext('Edit Patient Details'),
                  description: gettext('Add patient’s details.'),
                  component: PatientForm,
                  componentProps: {
                    id: record.uuid,
                    refetch,
                  },
                },
              },
              { type: 'divider' },
              record.status === 'in_progress'
                ? {
                    title: gettext('Mark As Completed'),
                    icon: <CheckIcon sx={{ color: variables.primary50P }} />,
                    wrapper: ModalTrigger,
                    wrapperProps: {
                      title: interpolate(
                        gettext(
                          'Mark %s’s participation in the study as completed?'
                        ),
                        [record.name]
                      ),
                      description: gettext(
                        'That means he has filled out all necessary forms and has all required labels.'
                      ),
                      actions: {
                        cancelButtonProps: {
                          children: gettext('Cancel'),
                        },
                        submitButtonProps: {
                          children: gettext('Mark As Completed'),
                          disabled: isMarkLoading,
                          onClick: () => changePatientStatus(record),
                        },
                      },
                    },
                  }
                : {
                    title: gettext('Mark As In Progress'),
                    icon: <ProgressIcon stroke={variables.primary50P} />,
                    wrapper: ModalTrigger,
                    wrapperProps: {
                      title: interpolate(
                        gettext(
                          'Mark %s’s participation in the study as in progress?'
                        ),
                        [record.name]
                      ),
                      description: gettext(
                        'This indicates that the study has not yet been completed for this patient.'
                      ),
                      actions: {
                        cancelButtonProps: {
                          children: gettext('Cancel'),
                        },
                        submitButtonProps: {
                          children: gettext('Mark As In Progress'),
                          disabled: isMarkLoading,
                          onClick: () => changePatientStatus(record),
                        },
                      },
                    },
                  },
              { type: 'divider' as const },
              {
                title: gettext('Add Label'),
                icon: <LocalOfferIcon sx={{ color: variables.primary50P }} />,
                wrapper: ModalTrigger,
                wrapperProps: {
                  title: interpolate(gettext('Add label for patient %s'), [
                    record.name,
                  ]),
                  description: gettext(
                    'Add a label that is suitable for this patient.'
                  ),
                  component: LabelForm,
                  componentProps: {
                    initialValues: { patient: record.uuid },
                    refetch,
                  },
                },
              },
            ]}
          />
        </div>
      ),
    }
  )
}
