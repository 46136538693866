import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Study } from 'types'

type StudyQueryOptions = Omit<UseQueryOptions<Study>, 'queryKey' | 'queryFn'>

const useStudy = (uuid?: string, options: StudyQueryOptions = {}) => {
  return useQuery<Study>({
    queryKey: [`studies/${uuid}`],
    enabled: Boolean(uuid),
    ...options,
  })
}

export default useStudy
