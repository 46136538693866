import { DefaultError, useQuery } from '@tanstack/react-query'
import { PaginationResponse, ResponseItemType } from 'types'

type FormsParams = Partial<{ study: string[] }>
type FormsQueryKey = ['forms', FormsParams]

type Form = ResponseItemType & { title: string }
type FormsResponse = PaginationResponse<Form>

const useForms = (params: FormsParams = {}) => {
  return useQuery<FormsResponse, DefaultError, Form[], FormsQueryKey>({
    queryKey: ['forms', params],
    select: (data) => data.results,
  })
}

export default useForms
