import { useMutation, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'

import api from 'common/services/api'
import { interpolate, useTranslations } from 'common/services/translations'
import getIdFromUuid from 'common/utils/getIdFromUuid'

type Args = {
  labelID: string
  requestID: string
}

const useRemoveRequestLabelQuery = ({ labelID, requestID }: Args) => {
  const queryClient = useQueryClient()
  const { gettext } = useTranslations()
  return useMutation({
    mutationFn: async () => {
      try {
        await api.delete(`patients/requests/labels/${labelID}`)
        queryClient.invalidateQueries({ queryKey: ['patients/requests'] })
        queryClient.invalidateQueries({
          queryKey: [`patients/requests/${requestID}`],
        })
        enqueueSnackbar(
          interpolate(
            gettext(
              'Label removed successfully. Request #%s is now under review'
            ),
            [getIdFromUuid(requestID)]
          ),
          { variant: 'success' }
        )
      } catch (error) {
        enqueueSnackbar(
          interpolate(gettext('Failed to remove label. Please try again'), []),
          { variant: 'error' }
        )
        console.log(error)
      }
    },
  })
}

export default useRemoveRequestLabelQuery
