import { Box, Paper, Stack, Typography } from '@mui/material'
import EventNoteIcon from '@mui/icons-material/EventNote'
import dayjs from 'dayjs'
import startCase from 'lodash-es/startCase'

import variables from 'assets/styles/_variables.module.scss'
import ActionsMenu, { ActionMenuItemType } from 'common/components/ActionsMenu'
import { interpolate, useTranslations } from 'common/services/translations'
import { getLabelFieldValue } from 'common/utils/labelHelpers'
import { Label } from 'types'

type LabelBlockProps = {
  label: Label
  actions?: ActionMenuItemType[]
  variant?: 'primary' | 'secondary'
}

export default function LabelDetails({
  label,
  actions,
  variant = 'secondary',
}: LabelBlockProps) {
  const { gettext } = useTranslations()

  const colors = {
    back: variables[`${variant}4P`],
    icon: variables[`${variant}50P`],
  }

  return (
    <Paper sx={{ px: 2, py: 1.5, backgroundColor: colors.back }}>
      <Stack direction="row" gap={0.5} alignItems="start">
        <EventNoteIcon sx={{ color: colors.icon, fontSize: 16 }} />
        <Box flexGrow={1}>
          <Typography variant="body2" fontWeight={500} mb={0.5}>
            {label.label_type?.name}
          </Typography>
          {label.label_type?.schema.map((field) => (
            <Typography key={field.field_key} component="p" variant="caption">
              <Box component="span" color="text.secondary">
                {startCase(field.field_key)}:
              </Box>{' '}
              {getLabelFieldValue(
                label.attributes[field.field_key],
                field.field_type,
                gettext
              )}
            </Typography>
          ))}
          <Typography
            component="p"
            variant="caption"
            color="text.disabled"
            fontStyle="italic"
            mt={1}
          >
            {interpolate(gettext('Added by %s, %s'), [
              label.created_by.name,
              dayjs(label.created).format('DD MMM, YYYY'),
            ])}
          </Typography>
        </Box>
        {actions && <ActionsMenu items={actions} />}
      </Stack>
    </Paper>
  )
}
