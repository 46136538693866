import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import logo from 'assets/img/logo-big.svg'

export default function AuthLayout() {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      gap={6}
      py={6}
      px={3}
      className="wrapper-bg"
    >
      <img src={logo} alt="Elixir" width="154" />
      <Paper
        elevation={6}
        sx={{
          width: '100%',
          maxWidth: '560px',
          p: 4,
          borderRadius: 2,
        }}
      >
        <Outlet />
      </Paper>
    </Grid>
  )
}
