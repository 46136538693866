import { UserType } from 'types'

export default function getUserFirstLetters(
  user?: Pick<UserType, 'first_name' | 'last_name'>
) {
  if (!user || (!user.first_name && !user.last_name)) {
    return ''
  }

  if (user.first_name && !user.last_name) {
    return user.first_name.slice?.(0, 2)?.toUpperCase?.()
  }

  if (!user.first_name && user.last_name) {
    return user.last_name.slice?.(0, 2)?.toUpperCase?.()
  }

  return `${user.first_name?.slice?.(0, 1)?.toUpperCase?.()}${user.last_name?.slice?.(0, 1)?.toUpperCase?.()}`
}
