import { Choice, ChoicesPath } from 'types'
import useChoices from 'common/hooks/useChoices'

import SelectField, { SelectFieldProps } from '.'

type Props = SelectFieldProps<Choice> & {
  path: ChoicesPath
}

export default function ChoicesSelectField({ path, ...props }: Props) {
  const options = useChoices(path)

  return (
    <SelectField
      {...props}
      options={options}
      valueKey="value"
      labelKey="label"
    />
  )
}
