import { useMemo } from 'react'
import * as Yup from 'yup'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material'
import { pick } from 'lodash-es'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'

import { Label, LabelType, RequestType } from 'types'
import variables from 'assets/styles/_variables.module.scss'
import useFormSubmit from 'common/hooks/useFormSubmit'
import {
  getFieldsInitialValues,
  LabelFields,
  getAttributesValidationSchema,
} from 'common/components/label'
import { interpolate, useTranslations } from 'common/services/translations'
import getIdFromUuid from 'common/utils/getIdFromUuid'

import {
  RequestLabelCreate,
  RequestLabelFormValues,
  RequestLabelUpdate,
} from './types'

const defaultFormValues: RequestLabelFormValues = {
  label_type: '',
  request: '',
  attributes: {},
}

type RequestLabelFormProps = {
  label?: Label
  request: RequestType
  labelType: LabelType
  toggleFormOpen: () => void
}

export default function RequestLabelForm({
  label,
  labelType,
  request,
  toggleFormOpen,
}: RequestLabelFormProps) {
  const isEdit = !!label

  const { gettext } = useTranslations()
  const queryClient = useQueryClient()

  const { generalError: errorCreate, submitForm: submitCreate } = useFormSubmit<
    RequestLabelFormValues,
    null,
    RequestLabelCreate
  >('patients/requests/labels')

  const { generalError: errorUpdate, submitForm: submitUpdate } = useFormSubmit<
    RequestLabelFormValues,
    null,
    RequestLabelUpdate
  >(`patients/requests/labels/${label?.uuid}`, 'patch')

  const handleSubmit = (
    values: RequestLabelFormValues,
    actions: FormikHelpers<RequestLabelFormValues>
  ) => {
    const onSuccess = () => {
      enqueueSnackbar(
        isEdit
          ? interpolate(gettext('Label "%s" updated successfully'), [
              labelType?.name ?? '',
            ])
          : interpolate(
              gettext('Label added successfully. Request #%s is completed'),
              [getIdFromUuid(request.uuid)]
            ),

        { variant: 'success' }
      )

      // invalidate request with old label data
      queryClient.invalidateQueries({
        queryKey: ['patients/requests'],
      })
      queryClient.invalidateQueries({
        queryKey: [`patients/requests/${request.uuid}`],
      })
      queryClient.invalidateQueries({
        queryKey: ['patients/requests', { patient: request.patient }],
      })
      toggleFormOpen()
    }

    if (isEdit) {
      return submitUpdate({
        data: pick(values, 'attributes'),
        actions,
        onSuccess,
      })
    }
    return submitCreate({
      data: values,
      actions,
      onSuccess,
    })
  }

  const generalError = errorCreate || errorUpdate

  const validationSchema = useMemo(
    () =>
      Yup.object({
        attributes: getAttributesValidationSchema(gettext, labelType?.schema),
      }),
    [gettext, labelType?.schema]
  )

  const initialValues: RequestLabelFormValues = {
    ...defaultFormValues,
    ...(isEdit
      ? {
          attributes: label?.attributes,
        }
      : getFieldsInitialValues(labelType?.schema)),
    request: request.uuid,
    label_type: labelType?.uuid ?? '',
  }

  return (
    <Box bgcolor={variables.primary4P} py={2} px={1.5}>
      <Formik<RequestLabelFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <Stack component={FormikForm} spacing={3}>
            {generalError && (
              <Alert severity="error" color="error" sx={{ mb: 2 }}>
                <AlertTitle>{gettext('Error')}</AlertTitle>
                {generalError}
              </Alert>
            )}
            <Typography fontSize={12} fontWeight={500}>
              {interpolate(gettext('Label: %s'), [labelType?.name ?? ''])}
            </Typography>
            <LabelFields schema={labelType?.schema} />
            <Stack direction="row" justifyContent="end" gap={1.5}>
              <Button variant="outlined" onClick={toggleFormOpen}>
                {gettext('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={isSubmitting}
              >
                {gettext('Save')}
              </Button>
            </Stack>
          </Stack>
        )}
      </Formik>
    </Box>
  )
}
