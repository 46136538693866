import { useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useTranslations } from 'common/services/translations'
import ContactSection from 'layouts/PublicLayout/ContactSection'
import { PaginationResponse, PublicStudyList } from 'types'

import List from './List'
import styles from './StudiesList.module.scss'

const MAX_ITEMS = 3

export default function StudiesList() {
  const [open, setOpen] = useState(false)
  const { gettext } = useTranslations()
  const navigate = useNavigate()

  const { data, isLoading } = useQuery<
    PaginationResponse<PublicStudyList>,
    null,
    PublicStudyList[]
  >({
    queryKey: ['studies/public/homepage-visible'],
    select: (data) => data.results,
  })
  const showMore = (data?.length || 0) > MAX_ITEMS

  const handleClick = (id: string) => navigate(`/studies/${id}`)

  return (
    <>
      <div className={styles.wrapper}>
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ maxWidth: 690, mx: 'auto' }}
        >
          <Typography
            variant="h3"
            color="primary.dark"
            fontWeight={800}
            textAlign="center"
          >
            {gettext('Elixir Studies')}
          </Typography>
          <Typography
            variant="body2"
            color="primary.dark"
            textAlign="center"
            sx={{ maxWidth: 600 }}
          >
            {gettext(
              'Here you can find detailed information about our ongoing and upcoming studies. Click on each study to learn more and find out how you can participate.'
            )}
          </Typography>
        </Stack>
        <Stack alignItems="center" sx={{ maxWidth: 800, mt: 6, mx: 'auto' }}>
          {isLoading && <CircularProgress />}
          <List studies={data?.slice(0, MAX_ITEMS)} onClick={handleClick} />
          {showMore && (
            <>
              <Collapse in={open} sx={{ width: '100%', mt: 3 }}>
                <List studies={data?.slice(MAX_ITEMS)} onClick={handleClick} />
              </Collapse>
              <Button
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                sx={{ mt: open ? 3 : 0 }}
                onClick={() => setOpen(!open)}
              >
                {open ? gettext('Show Less') : gettext('Show More')}
              </Button>
            </>
          )}
        </Stack>
      </div>
      <ContactSection />
    </>
  )
}
