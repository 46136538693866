import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useTranslations } from 'common/services/translations'
import blob from 'assets/img/blob.svg'

type Props = {
  onClick: () => void
}

export default function ForgotPasswordSuccess({ onClick }: Props) {
  const { gettext } = useTranslations()

  return (
    <>
      <Box component="img" src={blob} width="40px" height="58px" mb={3} />
      <Typography variant="h5" fontWeight={600} mb={1.5}>
        {gettext('Check Your Email')}
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={3}>
        {gettext(
          'If your email is registered, you will receive an email to reset your password.'
        )}
      </Typography>
      <Typography
        component="a"
        variant="subtitle2"
        color="secondary.light"
        fontWeight={600}
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        {gettext('Try again')}
      </Typography>
    </>
  )
}
