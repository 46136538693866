import { useState } from 'react'
import { Autocomplete, AutocompleteProps } from 'formik-mui'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'

import { PatientResponse } from 'types'
import { usePatientList } from 'common/api/patient'
import formatPhoneNumber from 'common/utils/formatters/formatPhoneNumber'
import useDebouncedValue from 'common/hooks/useDebouncedValue'
import { useTranslations } from 'common/services/translations'
import ModalWrapper from 'common/components/ModalTrigger/ModalWrapper'
import PatientForm from 'common/components/patient/Form'

import useStyles from './useStyles'
import PatientsList from './PatientsList'

type Props = AutocompleteProps<PatientResponse, false, false, false> & {
  onRefetch: (patientID: string) => void
}

export default function PatientAutocomplete({ onRefetch, ...props }: Props) {
  const [inputValue, setInputValue] = useState('')
  const [isModalOpen, setModalOpen] = useState(false)
  const debouncedInputValue = useDebouncedValue(inputValue)
  const { gettext } = useTranslations()
  const styles = useStyles()

  const enabled = debouncedInputValue.length >= 3
  const { data: patients } = usePatientList(
    { search: debouncedInputValue },
    {
      enabled,
    }
  )

  return (
    <>
      <Autocomplete
        {...props}
        sx={styles.autocomplete}
        options={patients || []}
        isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
        popupIcon={<SearchIcon sx={styles.icon} />}
        autoComplete
        includeInputInList
        filterSelectedOptions
        filterOptions={(option) => option}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        noOptionsText={
          enabled
            ? gettext('No patients')
            : gettext('Start typing at least 3 characters')
        }
        PaperComponent={(props) => (
          <PatientsList
            {...props}
            enabled={enabled}
            inputValue={inputValue}
            onClick={() => setModalOpen(true)}
          />
        )}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={gettext('Search for patient')} />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.uuid} sx={styles.option}>
            <Box py={1}>
              <Typography variant="subtitle1">{option.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {formatPhoneNumber(option.contact_number)}
              </Typography>
            </Box>
          </Box>
        )}
      />
      <ModalWrapper
        open={isModalOpen}
        title={gettext('New Patient')}
        description={gettext('Add patient’s details.')}
        component={PatientForm}
        componentProps={{ refetch: onRefetch }}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}
