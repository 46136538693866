import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import Header from './Header'

export default function AppLayout() {
  return (
    <Grid container wrap="nowrap" direction="column" className="wrapper-bg">
      <Header />
      <Grid
        item
        flexGrow={1}
        overflow="auto"
        py={{ xs: 2, sm: 3 }}
        px={{ xs: 2, sm: 4 }}
      >
        <Outlet />
      </Grid>
    </Grid>
  )
}
