import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

import { ColumnType } from './types'
import styles from './styles'

type Props<T> = ColumnType<T> & {
  ordering?: string
  onChange: (filters: { ordering: string }) => void
}

export default function Column<T>({
  id,
  name,
  sorting = false,
  ordering,
  onChange,
}: Props<T>) {
  const isAsc = ordering === id
  const isDesc = ordering === `-${id}`

  const handleSort = () => {
    onChange({ ordering: isDesc ? id : `-${id}` })
  }

  return (
    <TableCell sx={styles.headerCell}>
      {sorting && (
        <TableSortLabel
          active={isAsc || isDesc}
          direction={isAsc ? 'asc' : 'desc'}
          onClick={handleSort}
        >
          {name}
        </TableSortLabel>
      )}
      {!sorting && name}
    </TableCell>
  )
}
