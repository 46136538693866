import { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { RequestType, ResponseType } from 'types'
import usePhotoSwipe from 'common/hooks/usePhotoSwipe'
import { useTranslations } from 'common/services/translations'
import RequestDetails from 'common/components/request/Details'
import ResponseItem from 'common/components/response/Item'
import ResponsePreview from 'common/components/response/Preview'

import styles from './styles'

type Props = {
  request: RequestType
  response?: ResponseType
  refetch: () => void
}

export default function RequestBlock({ request, response, refetch }: Props) {
  const [expanded, setExpanded] = useState(false)
  const { gettext } = useTranslations()
  const gallery = `gallery-${request.uuid}`

  usePhotoSwipe(`#${gallery}`)

  return (
    <Paper id={gallery} sx={styles.request}>
      <RequestDetails request={request} refetch={refetch} />
      {response && (
        <>
          <Accordion
            disableGutters
            elevation={0}
            expanded={expanded}
            sx={styles.accordion}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}
              sx={styles.summary}
            >
              {!expanded && (
                <Grid
                  container
                  width="auto"
                  gap={1}
                  mr={1}
                  onClick={(e) => e.stopPropagation()}
                >
                  {response.answers.map((answer) =>
                    answer.attachments?.map((attachment) => (
                      <ResponsePreview
                        key={attachment}
                        attachment={attachment}
                        type={answer.answer_type}
                      />
                    ))
                  )}
                </Grid>
              )}
              <Typography variant="body2" fontWeight={500} flexShrink={0}>
                {expanded ? gettext('Hide Details') : gettext('More Details')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.details}>
              <Grid container flexDirection="column" gap={1.5}>
                {response?.answers?.map((answer) => (
                  <ResponseItem
                    key={answer.uuid}
                    answer={answer}
                    withoutPhotoswipe={!expanded}
                  />
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Paper>
  )
}
