import CachedIcon from '@mui/icons-material/Cached'
import dayjs from 'dayjs'

import { RequestType } from 'types'
import { ColumnType } from 'common/components/Table'
import formatNHS from 'common/utils/formatters/formatNHS'
import getIdFromUuid from 'common/utils/getIdFromUuid'
import { isActionAllowed } from 'common/utils/requestHelpers'
import { interpolate } from 'common/services/translations'
import ActionsMenu from 'common/components/ActionsMenu'
import ModalTrigger from 'common/components/ModalTrigger'
import Labels from 'common/components/patient/Labels'
import RequestForm from 'common/components/request/Form'
import Status from 'common/components/request/Status'
import { array } from 'common/utils/array'

import styles from './Requests.module.scss'

export default function getColumns(
  gettext: (text: string) => string,
  refetch: () => void,
  isStudyAdmin: boolean
) {
  return array<ColumnType<RequestType>>(
    isStudyAdmin && {
      id: 'hospital',
      name: gettext('Hospital'),
      render: (record) => record.hospital_name,
    },
    {
      id: 'uuid',
      name: gettext('Request #'),
      render: (record) => getIdFromUuid(record.uuid),
    },
    {
      id: 'patient_name',
      name: gettext('Name'),
      sorting: true,
      render: (record) => record.patient_name,
    },
    {
      id: 'nhs',
      name: gettext('NHS #'),
      render: (record) => formatNHS(record?.nhs),
    },
    {
      id: 'form_name',
      name: gettext('Request type'),
      render: (record) => record.form_name,
    },
    {
      id: 'created',
      name: gettext('Request Date'),
      sorting: true,
      render: (record) => dayjs(record.created).format('H:mm, D MMM, YYYY'),
    },
    {
      id: 'status',
      name: gettext('Status'),
      render: (record) => <Status type="request" status={record.status} />,
    },
    {
      id: 'label',
      name: gettext('Label'),
      render: (record) => (
        <Labels labels={record.label ? [record.label] : []} />
      ),
    },
    {
      id: 'actions',
      name: '',
      className: styles.actions,
      render: (record) =>
        isActionAllowed(record.status) && (
          <div onClick={(e) => e.stopPropagation()}>
            <ActionsMenu
              items={[
                {
                  title: gettext('Resend Request'),
                  icon: <CachedIcon color="secondary" />,
                  wrapper: ModalTrigger,
                  wrapperProps: {
                    title: gettext('Resend Request'),
                    description: interpolate(
                      gettext(
                        'Current request #%s will be canceled, provide the cancellation reason'
                      ),
                      [getIdFromUuid(record.uuid)]
                    ),
                    component: RequestForm,
                    componentProps: {
                      initialValues: {
                        cancellation_reason: '',
                        patient: record.patient,
                        note: record.note,
                        form: record.form,
                        phone_number: record.phone_number,
                        additional_sms_text: record.additional_sms_text,
                      },
                      resendId: record.uuid,
                      refetch,
                    },
                  },
                },
              ]}
            />
          </div>
        ),
    }
  )
}
