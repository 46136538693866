import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DiscountIcon from '@mui/icons-material/Discount'
import HubIcon from '@mui/icons-material/Hub'
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle'

import { useTranslations } from 'common/services/translations'

import styles from './Home.module.scss'

export default function HowSection() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.how}>
      <div className={styles.howContent}>
        <Stack alignItems="center" spacing={4}>
          <div>
            <Typography
              variant="subtitle2"
              color="secondary.light"
              fontWeight={600}
              textAlign="center"
              mb={1}
            >
              {gettext('A Streamlined Research Journey')}
            </Typography>
            <Typography
              variant="h4"
              color="primary.dark"
              fontWeight={700}
              textAlign="center"
            >
              {gettext('From Idea to Impact in Four Simple Steps')}
            </Typography>
          </div>
          <div>
            <Grid container spacing={{ xs: 3.75, md: 3 }}>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={2} className={styles.howCard}>
                  <AccountCircleIcon
                    sx={{ fontSize: 40, color: 'primary.main' }}
                  />
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight={600}
                  >
                    {gettext('Effortless Trial Design')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {gettext(
                      'Transform your research concepts into fully-fledged trials using our intuitive authoring tools, complete with automated regulatory compliance checks.'
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={2} className={styles.howCard}>
                  <HubIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight={600}
                  >
                    {gettext('Seamless Recruitment')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {gettext(
                      'Digitally enroll participants and obtain informed consent through our secure platform, reaching diverse populations with ease.'
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={2} className={styles.howCard}>
                  <DiscountIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight={600}
                  >
                    {gettext('Remote Data Collection')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {gettext(
                      'Gather valuable insights through user-friendly digital forms, reducing in-person visits and increasing participant engagement.'
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={2} className={styles.howCard}>
                  <LightbulbCircleIcon
                    sx={{ fontSize: 40, color: 'primary.main' }}
                  />
                  <Typography
                    variant="subtitle2"
                    color="text.primary"
                    fontWeight={600}
                  >
                    {gettext('Actionable Insights')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {gettext(
                      'Leverage our AI-powered analytics to uncover patterns, segment patients, and translate findings into improved care strategies.'
                    )}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </div>
          <Button href="/studies" variant="contained" size="large">
            {gettext('Join An Active Study')}
          </Button>
        </Stack>
      </div>
    </div>
  )
}
