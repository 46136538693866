import * as Yup from 'yup'

export default function getValidationSchema(gettext: (text: string) => string) {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, gettext('Minimum 8 characters'))
      .required(gettext('Required field')),
    password_confirm: Yup.string()
      .required(gettext('Please confirm your password'))
      .oneOf([Yup.ref('password')], gettext('Passwords do not match')),
  })
}
