import { Box, Button, styled } from '@mui/material'
import Sell from '@mui/icons-material/Sell'
import CancelIcon from '@mui/icons-material/Cancel'

import { RequestType } from 'types'
import editLabelIcon from 'assets/img/edit-label.svg'
import { useLabelTypes } from 'common/api/label'
import { useRemoveRequestLabel } from 'common/api/label'
import useToggle from 'common/hooks/useToggle'
import { LabelDetails } from 'common/components/label'
import ModalTrigger from 'common/components/ModalTrigger'
import { interpolate, useTranslations } from 'common/services/translations'
import getIdFromUuid from 'common/utils/getIdFromUuid'

import RequestLabelForm from './Form'
import variables from 'assets/styles/_variables.module.scss'

const Wrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}))

type RequestLabelBlockProps = {
  request: RequestType
}

export default function RequestLabelBlock({ request }: RequestLabelBlockProps) {
  const { gettext } = useTranslations()

  const [isFormOpen, toggleFormOpen] = useToggle(false)
  const { data } = useLabelTypes({ request: request.uuid })
  const { label } = request
  const labelType = data?.at(0)

  const { mutateAsync: removeLabel, isPending } = useRemoveRequestLabel({
    labelID: label?.uuid ?? '',
    requestID: request.uuid,
  })

  if (isFormOpen) {
    return (
      <RequestLabelForm
        label={label}
        request={request}
        toggleFormOpen={toggleFormOpen}
        labelType={label?.label_type ?? labelType!}
      />
    )
  }

  if (label) {
    return (
      <Wrapper>
        <LabelDetails
          variant="primary"
          label={{ ...label, label_type: labelType! }}
          actions={[
            {
              title: gettext('Edit Label'),
              onClick: toggleFormOpen,
              icon: (
                <Box
                  component="img"
                  color={variables.primary50P}
                  src={editLabelIcon}
                  width={24}
                  height={24}
                  alt={gettext('Edit label')}
                />
              ),
            },
            {
              title: gettext('Remove Label'),
              onClick: removeLabel,
              icon: <CancelIcon sx={{ color: variables.primary50P }} />,
              wrapper: ModalTrigger,
              wrapperProps: {
                title: interpolate(gettext('Remove label from request #%s?'), [
                  getIdFromUuid(request.uuid),
                ]),
                description: interpolate(
                  gettext(
                    "Are you sure you want to remove the label from request #%s? This will change the request status to 'Review' until a new label is added."
                  ),
                  [getIdFromUuid(request.uuid)]
                ),
                actions: {
                  cancelButtonProps: {
                    children: gettext('Cancel'),
                  },
                  submitButtonProps: {
                    children: gettext('Remove Label'),
                    disabled: isPending,
                    onClick: () => removeLabel(),
                  },
                },
              },
            },
          ]}
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Button
        sx={{ my: 1 }}
        variant="outlined"
        size="small"
        color="secondary"
        startIcon={<Sell />}
        onClick={toggleFormOpen}
        disabled={!labelType}
      >
        {gettext('Add Request Label')}
      </Button>
    </Wrapper>
  )
}
