import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import MUITable from '@mui/material/Table'
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import { ResponseItemType } from 'types'
import NoResult, { NoResultProps } from 'common/components/NoResult'

import { ColumnType } from './types'
import styles from './styles'
import Column from './Column'
import Row from './Row'

export type { ColumnType }

type Props<T> = {
  columns: ColumnType<T>[]
  data?: T[]
  isLoading?: boolean
  count?: number
  offset?: number
  limit?: number
  ordering?: string
  keyName?: keyof T
  noResultProps?: NoResultProps
  getRowClassName?: (record: T) => string
  onChange: (filters: { ordering?: string; offset?: number }) => void
  onRowClick?: (record: T) => void
}

export default function Table<T extends ResponseItemType>({
  columns,
  data = [],
  isLoading = false,
  count = 0,
  offset = 0,
  limit = 0,
  ordering,
  keyName = 'uuid',
  noResultProps,
  getRowClassName,
  onChange,
  onRowClick,
}: Props<T>) {
  const page = offset ? offset / limit : 0
  const handleChangePage = (_: unknown, page: number) => {
    onChange({ offset: page * limit })
  }

  if (!isLoading && !data.length && noResultProps) {
    return <NoResult {...noResultProps} />
  }

  return (
    <Paper sx={styles.paper}>
      <Backdrop open={isLoading} sx={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      <TableContainer>
        <MUITable size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <Column<T>
                  key={column.id}
                  {...column}
                  ordering={ordering}
                  onChange={onChange}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((record) => (
              <Row<T>
                key={String(record[keyName])}
                record={record}
                columns={columns}
                getRowClassName={getRowClassName}
                onClick={onRowClick}
              />
            ))}
          </TableBody>
        </MUITable>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
        padding="none"
        sx={styles.pagination}
        onPageChange={handleChangePage}
      />
    </Paper>
  )
}
