import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import api from 'common/services/api'

export default function useQueryWithFilters<
  T,
  F extends Record<string, string | string[]>,
>(
  queryKey: string,
  endpoint: string,
  defaultParams: Partial<F>,
  options: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>
) {
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useMemo(
    () =>
      [...searchParams.entries()].reduce<F>(
        (obj, [key, val]) => ({
          ...obj,
          [key]: obj[key] ? [obj[key], val].flat() : val,
        }),
        {} as F
      ),
    [searchParams]
  )

  const result = useQuery<T, Error, T>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      api.get(endpoint, { params: { ...defaultParams, ...filters } }),
    ...options,
  })

  const addFilters = useCallback(
    (newFilters = {}) => {
      const updatedFilters = {
        ...filters,
        ...('offset' in filters ? { offset: '0' } : {}),
        ...newFilters,
      }

      setSearchParams(updatedFilters)
    },
    [filters, setSearchParams]
  )

  const resetFilters = useCallback(() => {
    setSearchParams(defaultParams as F)
  }, [defaultParams, setSearchParams])

  return {
    ...result,
    filters: {
      ...defaultParams,
      ...filters,
    },
    addFilters,
    resetFilters,
  }
}
