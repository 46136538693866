import { useMemo } from 'react'

import { useAuth } from 'common/services/auth'

import AccessType, * as accesses from './access'

export default function useCheckAccess(access: AccessType) {
  const { user } = useAuth()
  const accessArr = [access].flat()
  const level = useMemo(
    () =>
      new Set([
        accesses.F_PUBLIC,
        user ? accesses.F_PROTECTED : accesses.F_UNAUTHORIZED,
        user?.role === 'study_admin' ? accesses.F_STUDY_ADMIN : '',
      ]),
    [user]
  )

  return accessArr.map((a) => level.has(a))
}
