import { Context, createContext } from 'react'

import { Study, UserType } from 'types'

export type SystemConfig = {
  contact_admin_email: string
}

type AuthContextType = {
  user?: UserType
  config?: SystemConfig
  currentStudy: Study | null
  setStudy: (study: Study['uuid']) => void
  refetchUser: () => void
  removeUser: () => void
}

const AuthContext: Context<AuthContextType> = createContext(
  {} as AuthContextType
)

export default AuthContext
