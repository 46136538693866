import { DefaultError, useQuery } from '@tanstack/react-query'
import { LabelType, PaginationResponse } from 'types'

type LabelTypesFilters = {
  request?: string
  patient?: string
}
type LabelTypesQueryKey = ['labels', LabelTypesFilters]

const useLabelTypes = (filters: LabelTypesFilters) => {
  return useQuery<
    PaginationResponse<LabelType>,
    DefaultError,
    LabelType[],
    LabelTypesQueryKey
  >({
    queryKey: ['labels', filters],
    select: (data) => data.results,
  })
}

export default useLabelTypes
