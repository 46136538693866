import { useCallback } from 'react'
import Box from '@mui/material/Box'
import { useQuery } from '@tanstack/react-query'
import { Stack } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import MuiDrawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

import { PatientResponse, RequestType, ResponseType } from 'types'
import { access, CheckAccess } from 'common/access'
import { useLabelTypes } from 'common/api/label'
import { useTranslations } from 'common/services/translations'
import PatientDetails from 'common/components/patient/Details'
import variables from 'assets/styles/_variables.module.scss'

import Details from '../Details'
import ResponseBlock from './ResponseBlock'

type Props = {
  uuid: string | null
  onClose: () => void
  refetch: () => void
}

export default function Drawer({ uuid, onClose, refetch }: Props) {
  const { gettext } = useTranslations()
  const {
    data: request,
    isLoading: isRequestLoading,
    dataUpdatedAt,
    refetch: refetchRequest,
  } = useQuery<RequestType>({
    queryKey: [`patients/requests/${uuid ?? ''}`],
    enabled: Boolean(uuid),
  })
  const { data: response } = useQuery<ResponseType>({
    queryKey: [`patients/responses/${request?.response}`],
    enabled: Boolean(uuid && request?.response),
  })
  const {
    data: patient,
    isLoading: isPatientLoading,
    refetch: refetchPatient,
  } = useQuery<PatientResponse>({
    queryKey: [`patients/${request?.patient}`],
    enabled: Boolean(uuid && request?.patient),
  })

  // prefetch label types on drawer open
  const { isLoading: isLabelsLoading } = useLabelTypes({
    request: request?.uuid,
  })

  const handleRefetch = useCallback(() => {
    refetch()
    refetchRequest()
  }, [refetch, refetchRequest])

  const isLoading = isRequestLoading || isPatientLoading || isLabelsLoading

  return (
    <MuiDrawer
      anchor="right"
      open={Boolean(uuid)}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '516px',
        },
      }}
      ModalProps={{
        disableEnforceFocus: true,
      }}
      onClose={onClose}
    >
      <Stack direction="row" alignItems="center" py={2} pl={1} pr={3}>
        <IconButton size="small" sx={{ mr: '10px' }} onClick={onClose}>
          <CloseIcon sx={{ color: 'primary.light' }} />
        </IconButton>
        <Typography variant="h6" fontWeight={500}>
          {gettext('Request details')}
        </Typography>
        <CheckAccess access={access.F_STUDY_ADMIN}>
          {request && !isLoading && (
            <Typography
              ml="auto"
              fontSize={14}
              fontWeight={600}
              color={variables.primary50P}
            >
              {request.hospital_name}
            </Typography>
          )}
        </CheckAccess>
      </Stack>
      <Divider />
      {isLoading && (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {request && !isLoading && (
        <Box py={3} px={2}>
          {patient && (
            <PatientDetails
              patient={patient}
              sx={{ p: 1.5, boxShadow: 'none' }}
              withAgeBackground
              refetch={refetchPatient}
            />
          )}
          <Box mt={3} px={1.5}>
            <Details
              key={dataUpdatedAt}
              request={request}
              refetch={handleRefetch}
            />
            {response && <ResponseBlock response={response} />}
          </Box>
        </Box>
      )}
    </MuiDrawer>
  )
}
