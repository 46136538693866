import { DefaultError, useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { PatientStatus } from 'types'
import api from 'common/services/api'
import { interpolate, useTranslations } from 'common/services/translations'

type Config = {
  url: string
  label: string
}

type Response = { id: string }
type Variables = {
  uuid: string
  name: string
  status: PatientStatus
}

const useChangePatientStatus = (onSuccess?: () => void) => {
  const { enqueueSnackbar } = useSnackbar()
  const { gettext } = useTranslations()

  const configMap: Record<PatientStatus, Config> = {
    in_progress: {
      url: 'complete',
      label: gettext('complete'),
    },
    completed: {
      url: 'mark-as-in-progress',
      label: gettext('in progress'),
    },
  }

  return useMutation<Response, DefaultError, Variables>({
    mutationFn: ({ uuid, status }) =>
      api.post(`patients/${uuid}/${configMap[status].url}`, {}),
    onSuccess: (_, { name, status }) => {
      enqueueSnackbar(
        interpolate(
          gettext('%s’s participation in the study was marked as %s'),
          [name, configMap[status].label]
        ),
        {
          variant: 'success',
        }
      )
      onSuccess?.()
    },
    onError: (_, { status }) => {
      enqueueSnackbar(
        interpolate(gettext('Failed to mark as %s'), [configMap[status].label]),
        {
          variant: 'error',
        }
      )
    },
  })
}

export default useChangePatientStatus
