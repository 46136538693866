import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import EventNoteIcon from '@mui/icons-material/EventNote'
import dayjs from 'dayjs'
import startCase from 'lodash-es/startCase'

import { interpolate, useTranslations } from 'common/services/translations'
import { getLabelFieldValue } from 'common/utils/labelHelpers'
import { Label } from 'types'
import variables from 'assets/styles/_variables.module.scss'

import styles from './Labels.module.scss'

type LabelsProps = {
  labels?: Label[]
  maxCount?: number
}

export default function Labels({ labels, maxCount }: LabelsProps) {
  const { gettext } = useTranslations()
  const items = labels?.slice(0, maxCount)
  const rest = (labels?.length || 0) - (items?.length || 0)

  return items?.length ? (
    <Stack direction="row" spacing={0.25} useFlexGap flexWrap="wrap">
      {items?.map((label, index) => (
        <Tooltip
          key={index}
          title={
            <div>
              {label.label_type.schema.map((field) => (
                <Typography
                  key={field.field_key}
                  variant="body2"
                  fontWeight={500}
                >
                  {startCase(field.field_key)}:{' '}
                  {getLabelFieldValue(
                    label.attributes[field.field_key],
                    field.field_type,
                    gettext
                  )}
                </Typography>
              ))}
              <Typography
                variant="caption"
                color="text.disabled"
                fontStyle="italic"
              >
                {interpolate(gettext('Added by %s, %s'), [
                  label.created_by.name,
                  dayjs(label.created).format('DD MMM, YYYY'),
                ])}
              </Typography>
            </div>
          }
        >
          <Chip
            label={label.label_type.name}
            size="small"
            color="secondary"
            icon={<EventNoteIcon />}
            className={styles.label}
          />
        </Tooltip>
      ))}
      {!!rest && (
        <Tooltip
          title={<Labels labels={labels?.slice(maxCount)} />}
          componentsProps={{
            tooltip: { sx: { backgroundColor: variables.primary4P } },
          }}
        >
          <Chip
            label={`+${rest}`}
            size="small"
            color="secondary"
            className={styles.label}
          />
        </Tooltip>
      )}
    </Stack>
  ) : (
    '-'
  )
}
