import styles from './PrivacyPolicy.module.scss'

import React from 'react'

const PrivacyPolicy: React.FC = () => (
  <div className={styles.privacyPolicyContent}>
    <h1>Elixir’s Privacy Policy</h1>
    <p>
      <strong>Effective: 22nd August 2024</strong>
    </p>

    <h2>Contents</h2>
    <ul>
      <li>
        <a href="#introduction">Introduction</a>
      </li>
      <li>
        <a href="#who-are-we">Who are we?</a>
      </li>
      <li>
        <a href="#data">Data Policies</a>
      </li>
      <li>
        <a href="#cookies">Cookies</a>
      </li>
    </ul>

    <h2 id="introduction">Introduction</h2>
    <p>
      At Elixir, privacy and information governance are paramount to our
      operations. This Privacy Notice outlines our practices for handling
      personal information across various stakeholders:
    </p>
    <ul>
      <li>Users in healthcare organisations and universities</li>
      <li>Children under their care or participating in research studies</li>
      <li>Parents and guardians of these children</li>
      <li>Job candidates, employees, and contractors</li>
    </ul>
    <p>
      We have established data sharing and collaboration agreements with several
      NHS trusts, including Birmingham Women's and Children's NHS Foundation
      Trust (BWC). These agreements strictly govern our processes for handling
      patient data on behalf of these healthcare organisations.
    </p>
    <p>
      Elixir specialises in developing software that streamlines the execution
      of clinical trials for medical professionals. Our aim is to foster
      innovation and enhance clinical care for children. In this capacity, we
      act as data processors for patient-identifiable information, with the
      healthcare organisations serving as data controllers, as stipulated in our
      data processing agreement.
    </p>
    <p>
      The policies outlined herein apply to all Elixir software, websites, and
      services. For any inquiries regarding this policy, please contact us at{' '}
      <a href="mailto:info@elixir.ai">info@elixir.ai</a>.
    </p>

    <h2 id="who-are-we">Who are we?</h2>
    <p>
      Our full company name is Elixir AI Ltd. <br />
      Our office is at 7 Florence Gardens, Manningtree, CO11 2FB <br />
      Company Registration Number is 1075526 <br />
      ICO Registration Number is ZA803609
    </p>
    <p>
      Our Data Protection Officer is Dr. Edward Cartwright. <br />
      You can contact our DPO via email:{' '}
      <a href="mailto:info@elixir.ai">info@elixir.ai</a>.
    </p>

    <h2 id="data">
      What personally identifiable information do we collect about you, and why?
    </h2>
    <h3>As a healthcare professional</h3>
    <p>
      Healthcare professionals can create an Elixir account to access our
      services. When you create an account, we collect and associate the
      following information with a unique identifier in our system:
    </p>
    <ul>
      <li>Full name</li>
      <li>Professional email address</li>
    </ul>
    <p>
      Through your use of our software platforms, we may collect additional
      information as required:
    </p>
    <ul>
      <li>Affiliated healthcare organisation</li>
      <li>Job role and professional credentials</li>
      <li>
        Content of communications with or about patients, including clinical
        labels for information provided by families
      </li>
      <li>
        Usage data, including functions accessed, devices used, and software
        utilised to connect to our platforms
      </li>
      <li>Phone number</li>
    </ul>
    <p>
      We collect and process this data to provide software services as agreed
      upon with your organisation, governed by our Terms and Conditions and any
      existing contractual relationships.
    </p>
    <p>Elixir's software services facilitate:</p>
    <ul>
      <li>Collection of clinical information for research trials</li>
      <li>
        Direct communication between healthcare professionals and patients’
        parents or guardians
      </li>
      <li>Inter-professional communication among healthcare providers</li>
    </ul>
    <p>
      Our data collection and processing practices comply with all relevant data
      protection regulations and are designed to support the efficient and
      secure conduct of clinical research and patient care.
    </p>

    <h3>As a parent/guardian of a child participating in a clinical trial</h3>
    <p>
      When your child is eligible for and joins a study hosted on our platform,
      we process information provided by your healthcare workers to facilitate
      the study. We act strictly as data processors, following the instructions
      of the healthcare organisations (the data controllers) in line with our
      data processing agreement.
    </p>
    <p>
      On behalf of the data controller (your healthcare organisation), we handle
      the following information about your child:
    </p>
    <ul>
      <li>Full name</li>
      <li>NHS number</li>
      <li>Date of birth</li>
      <li>Ethnicity</li>
      <li>Postcode</li>
    </ul>
    <p>For communication purposes, we use:</p>
    <ul>
      <li>Your mobile phone number</li>
      <li>Your email address</li>
    </ul>
    <p>
      This contact information enables secure communication about your child's
      participation in the study, including SMS and email notifications, and
      facilitates phone calls from the study team when necessary.
    </p>
    <p>Our platform safely collects, stores, and transmits:</p>
    <ul>
      <li>Study-related communications, including:</li>
      <ul>
        <li>
          Messages from healthcare providers (e.g., your child's paediatrician)
        </li>
        <li>Your responses to healthcare professionals, such as:</li>
        <ul>
          <li>Completed questionnaires about your child</li>
          <li>Images, videos or audio requested for the study</li>
        </ul>
      </ul>
      <li>Clinical data, including:</li>
      <ul>
        <li>
          Records of your child's treatment created by professionals using our
          software
        </li>
        <li>Relevant blood test results</li>
      </ul>
      <li>Technical data:</li>
      <ul>
        <li>
          Information about the devices and software you use to access our
          services
        </li>
      </ul>
    </ul>
    <p>
      When explicitly instructed by the data controller, we may access and
      process information from other clinical systems to which your child's
      healthcare provider has access. This ensures that all relevant information
      is available to the study team and other involved professionals.
    </p>
    <p>We also collect usage data, such as:</p>
    <ul>
      <li>When you access our platform</li>
      <li>Which features you use</li>
      <li>The type of device you use</li>
    </ul>
    <p>This data allows us to:</p>
    <ul>
      <li>Maintain clear audit trails</li>
      <li>Improve our software</li>
      <li>Ensure the clinical safety of our products and services</li>
      <li>
        Monitor for and prevent fraud, cyberattacks, and other security threats
      </li>
    </ul>
    <p>Throughout the study, you may receive:</p>
    <ul>
      <li>Secure links via email and SMS to access our website</li>
      <li>Requests to complete questionnaires</li>
      <li>Requests to share clinical information, images, or videos</li>
    </ul>
    <p>
      Our platform is designed to support you through every stage of the
      clinical trial process, from informed consent to data collection, ensuring
      a seamless and secure experience for you and your child.
    </p>

    <h2>Other groups of people who Elixir process information about</h2>
    <p>
      We process corporate prospects’ contacts or past corporate clients’
      contacts data, including for direct marketing purposes, subject to the
      right to object and any opt-out exercised.
    </p>
    <p>
      We process job candidates’ CVs and related data as long as this may be
      required in relation to the selection process.
    </p>

    <h2>How specific Elixir software features work</h2>
    <p>
      Elixir's platform is designed to facilitate and streamline clinical
      research studies. Here are some key features:
    </p>
    <ul>
      <li>
        <strong>Secure Participant Portal:</strong> Our platform provides a
        secure, user-friendly interface for study participants. Parents and
        guardians can access study information, provide consent, and submit
        required data through this portal.
      </li>
      <li>
        <strong>Multi-modal Data Collection:</strong> Elixir supports various
        types of data collection, including questionnaires, image uploads, video
        submissions, and audio recordings. This flexibility allows researchers
        to gather comprehensive data while maintaining participant privacy.
      </li>
      <li>
        <strong>Automated Notifications:</strong> Our system sends timely
        reminders via SMS and email to ensure participants complete study tasks
        on schedule. These notifications contain secure, unique links to
        maintain data integrity and participant privacy.
      </li>
      <li>
        <strong>Real-time Data Processing:</strong> As data is submitted, our
        platform processes it in real-time, allowing researchers to monitor
        study progress and identify any potential issues promptly.
      </li>
      <li>
        <strong>Audit Trail and Compliance:</strong> Every interaction within
        the platform is logged, creating a comprehensive audit trail. This
        feature ensures compliance with regulatory requirements and maintains
        the integrity of the research process.
      </li>
      <li>
        <strong>Anonymisation and Pseudonymisation:</strong> Where required by
        the study protocol, our platform can automatically anonymise or
        pseudonymise data to protect participant identities while preserving
        data utility for research purposes.
      </li>
    </ul>

    <h2>Data Ownership and Processing</h2>
    <p>
      The NHS Trusts or Universities that Elixir works with retain ownership of
      all existing patient data, including blood test results, diagnoses,
      imaging and investigations history, clinical history, surgical history,
      and any existing clinical images or data collected as part of routine
      clinical care.
    </p>
    <p>
      Elixir owns all rights to new data collected directly from parents through
      our software, including clinical information and any photos, videos, or
      audio recordings submitted specifically for research purposes.
    </p>
    <p>
      Both parties have agreed to grant each other a non-exclusive, royalty-free
      license to use the other party's owned data for the purposes of our
      collaboration and as specified in their collaboration agreements.
    </p>
    <p>This arrangement ensures that:</p>
    <ul>
      <li>Clinical data remains under the control of healthcare providers</li>
      <li>Research-specific data can be efficiently collected and processed</li>
      <li>
        Both clinical care and research activities benefit from shared insights
      </li>
    </ul>
    <p>
      All data processing activities are conducted in strict compliance with
      data protection regulations and ethical research standards. Our platform's
      design ensures that data ownership and access rights are consistently
      enforced throughout the research process.
    </p>

    <h2>What is the legal basis for processing data?</h2>
    <p>
      Elixir operates primarily as a data processor for patient data shared by
      healthcare providers through our software services. For research studies,
      express consent is obtained from participants. This approach aligns with
      the highest ethical standards and data protection regulations.
    </p>
    <p>The legal bases for processing data in our services are as follows:</p>
    <h3>For Research Studies:</h3>
    <ul>
      <li>
        Article 6(1)(a) of the GDPR – Processing based on the data subject's
        explicit consent
      </li>
      <li>
        Article 9(2)(a) of the GDPR – Processing of special category data
        (including health data) based on explicit consent
      </li>
    </ul>
    <h3>For Healthcare Providers:</h3>
    <ul>
      <li>
        Article 6(1)(e) of the GDPR – Processing necessary for the performance
        of a task carried out in the public interest or in the exercise of
        official authority
      </li>
      <li>
        Article 9(2)(h) of the GDPR – Processing necessary for the purposes of
        preventive or occupational medicine, medical diagnosis, the provision of
        health or social care or treatment
      </li>
      <li>
        Article 9(2)(i) of the GDPR – Processing necessary for reasons of public
        interest in the area of public health
      </li>
    </ul>
    <h3>For Processing Ethnicity Data:</h3>
    <ul>
      <li>
        Article 9(2)(h) of the GDPR – Processing necessary for the purposes of
        preventive or occupational medicine, medical diagnosis, the provision of
        health or social care or treatment
      </li>
      <li>
        Article 9(2)(b) of the GDPR – Processing necessary for the purposes of
        carrying out obligations in the field of social protection law (for
        monitoring equality of access)
      </li>
    </ul>
    <h3>Where Elixir Acts as a Data Controller:</h3>
    <ul>
      <li>
        Article 6(1)(b) of the GDPR – Processing necessary for the performance
        of a contract to which the data subject is party
      </li>
      <li>
        Article 6(1)(f) of the GDPR – Processing necessary for the purposes of
        the legitimate interests pursued by the controller, except where such
        interests are overridden by the interests or fundamental rights and
        freedoms of the data subject
      </li>
    </ul>
    <p>
      We strictly adhere to these legal bases in all our data processing
      activities. Any use of Elixir's software for purposes beyond those
      outlined above would constitute a misuse of the system and a breach of our
      terms and conditions.
    </p>
    <p>
      Our commitment to data protection goes beyond mere compliance. We strive
      to uphold the highest standards of ethical data handling, ensuring that
      the rights and freedoms of individuals are respected at every stage of the
      data processing journey.
    </p>

    <h2>Do we share this data with third parties?</h2>
    <p>
      Elixir utilises third-party data processors for essential services such as
      email, productivity tools, design software, communications platforms, and
      data storage.
    </p>
    <p>In the context of research studies:</p>
    <ul>
      <li>
        Patient information may be shared with other healthcare and research
        organisations as specified in the study protocol and consent form. This
        sharing is strictly limited to the instructions provided by the
        healthcare provider or research institution and is always in compliance
        with applicable data protection regulations.
      </li>
      <li>
        We compile anonymised statistics about the use of our platform,
        including feature utilisation by researchers and participants. All
        personal data is removed through aggregation at the institutional level
        or higher. We may share these aggregate usage statistics with:
      </li>
      <ul>
        <li>
          National bodies such as NHS Digital, NHS England, and relevant
          government departments
        </li>
        <li>Local commissioning bodies</li>
        <li>Research ethics committees</li>
        <li>Funding bodies</li>
        <li>Academic partners</li>
        <li>Commercial partners involved in medical research</li>
      </ul>
    </ul>
    <p>
      Any sharing of aggregated data is done with the explicit aim of improving
      healthcare research processes and outcomes.
    </p>

    <h2>How long do we retain data for?</h2>
    <p>
      Our data retention practices are designed to balance research needs,
      regulatory requirements, and individual privacy rights:
    </p>
    <h3>Clinical Data:</h3>
    <p>
      We adhere to the Records Management Code of Practice for Health and Social
      Care for clinical data. However, we will delete data earlier if informed
      that the conditions under Article 9(3) GDPR and Section 11(1) Data
      Protection Act 2018 no longer apply.
    </p>
    <h3>Research Data:</h3>
    <p>
      We retain research-related data in accordance with the specific
      requirements of each study protocol and applicable regulations.
    </p>
    <p>
      Six months after a study ends, we transfer all institution-owned data back
      to the respective NHS trust or university and remove all identifiable data
      from our systems.
    </p>
    <p>
      We retain anonymised institutional data in accordance with our
      collaboration agreements, which typically specify retention periods
      necessary for research validation and potential follow-up studies.
    </p>
    <p>
      Elixir-owned data, primarily consisting of platform usage analytics and
      non-identifiable user behaviour data, is retained and processed as
      specified in our agreements to improve our services and support future
      research initiatives.
    </p>
    <h3>Institutional Data:</h3>
    <p>
      Data pertaining to our clients' research teams and non-research personnel
      involved in our services is retained for as long as necessary to provide
      our services, support ongoing research, or pursue legitimate business
      interests. This is subject to the individual's right to object or opt-out
      of marketing communications.
    </p>
    <h3>Participant Rights:</h3>
    <p>
      Research participants may contact us at{' '}
      <a href="mailto:info@elixir.ai">info@elixir.ai</a> to request information
      about their data or to exercise their rights under applicable data
      protection laws, including the right to erasure where appropriate and not
      in conflict with research integrity requirements.
    </p>
    <p>
      Our retention practices are regularly reviewed to ensure compliance with
      evolving data protection regulations and research ethics standards. We are
      committed to maintaining the delicate balance between advancing medical
      research and protecting individual privacy rights.
    </p>

    <h2>How to contact us?</h2>
    <p>
      If you have questions or concerns about privacy, or wish to exercise
      rights you have in relation to personal data we process about you, you can
      email <a href="mailto:info@elixir.com">info@elixir.com</a> or write to
      Elixir AI Ltd, 7 Florence Gardens, Manningtree, CO11 2FB.
    </p>
    <p>
      You may always make further enquiries to{' '}
      <a href="https://www.ico.org.uk">www.ico.org.uk</a>
    </p>

    <h2>Future updates to this Notice</h2>
    <p>
      This notice may change periodically and will be published on the Elixir
      website. Subscribers of our monthly email newsletter will also be notified
      of major changes in the subsequent newsletter.
    </p>

    <h2 id="cookies">Use of cookies</h2>
    <p>
      Our website and research platform use cookies and similar technologies to
      enhance user experience, analyse usage patterns, and ensure the security
      and functionality of our services. Cookies are small text files stored on
      your device that help us recognise you and your preferences across
      multiple visits.
    </p>
    <p>We use the following types of cookies:</p>
    <ul>
      <li>
        <strong>Essential Cookies:</strong> These are necessary for the website
        and our research platform to function properly. They enable core
        features such as user authentication, security, and session management.
      </li>
      <li>
        <strong>Functional Cookies:</strong> These cookies allow us to remember
        choices you make and provide enhanced, more personal features. For
        example, they may be used to remember your login details or language
        preferences.
      </li>
      <li>
        <strong>Analytics Cookies:</strong> We use these to collect information
        about how you use our website and research platform, helping us improve
        our services. These cookies collect aggregated, anonymous data and do
        not identify you personally.
      </li>
      <li>
        <strong>Performance Cookies:</strong> These cookies help us understand
        how visitors interact with our website by collecting and reporting
        information anonymously.
      </li>
      <li>
        <strong>Research-Specific Cookies:</strong> For participants in research
        studies, we may use cookies to maintain the integrity of the research
        process, such as ensuring consistent data collection across multiple
        sessions.
      </li>
    </ul>
    <p>
      You can control and/or delete cookies as you wish. You can delete all
      cookies that are already on your computer and you can set most browsers to
      prevent them from being placed. However, if you do this, you may have to
      manually adjust some preferences every time you visit our site and some
      services and functionalities may not work.
    </p>
    <p>
      By using our website and research platform, you agree to the use of
      cookies as described above. If you wish to opt-out of non-essential
      cookies, you can adjust your browser settings or contact us for
      assistance. For more information about cookies, including how to see what
      cookies have been set and how to manage and delete them, visit{' '}
      <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>. If
      you have any questions about our use of cookies, please contact us at{' '}
      <a href="mailto:info@elixir.ai">info@elixir.ai</a>.
    </p>
  </div>
)

export default PrivacyPolicy
