export const F_PUBLIC = 'public'
export const F_UNAUTHORIZED = 'unauthorized'
export const F_PROTECTED = 'protected'
export const F_STUDY_ADMIN = 'study_admin'

type AccessType =
  | typeof F_PUBLIC
  | typeof F_UNAUTHORIZED
  | typeof F_PROTECTED
  | typeof F_STUDY_ADMIN

export default AccessType
