import Grid from '@mui/material/Grid'

import { ResponseType } from 'types'
import usePhotoSwipe from 'common/hooks/usePhotoSwipe'
import ResponseItem from 'common/components/response/Item'

type Props = {
  response: ResponseType
}

const id = 'response'

export default function ResponseBlock({ response }: Props) {
  usePhotoSwipe(`#${id}`)

  return (
    <Grid id={id} container flexDirection="column" gap={1} mt={3}>
      {response?.answers?.map((answer) => (
        <ResponseItem key={answer.uuid} answer={answer} />
      ))}
    </Grid>
  )
}
