import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export default function ProgressIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
        fill="transparent"
      >
        <path
          d="M7.99902 2.25781C4.4205 2.25781 1.51953 5.05254 1.51953 8.5C1.51953 10.5903 2.58601 12.4406 4.22337 13.5735"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeDasharray="0.1 3"
        />
        <path
          d="M7.99932 2.25781C11.5778 2.25781 14.4788 5.05254 14.4788 8.5C14.4788 11.9475 11.5778 14.7422 7.99932 14.7422C6.89185 14.7422 5.84928 14.4745 4.9375 14.0027"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
