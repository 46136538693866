import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { ColumnType } from './types'
import styles from './styles'

type Props<T> = {
  record: T
  columns: ColumnType<T>[]
  getRowClassName?: (record: T) => string
  onClick?: (record: T) => void
}

export default function Row<T>({
  record,
  columns,
  getRowClassName,
  onClick,
}: Props<T>) {
  const rowClassName = getRowClassName?.(record)

  const handleClick = () => {
    onClick?.(record)
  }

  return (
    <TableRow className={rowClassName} sx={styles.row} onClick={handleClick}>
      {columns.map(({ id, className, render }) => (
        <TableCell key={id} sx={styles.cell} className={className}>
          {render(record)}
        </TableCell>
      ))}
    </TableRow>
  )
}
