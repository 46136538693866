import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useTranslations } from 'common/services/translations'

import styles from './Home.module.scss'

export default function HeroSection() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.hero}>
      <div className={styles.heroContent}>
        <Stack
          spacing={4}
          alignItems="start"
          className={styles.heroDescription}
        >
          <Typography
            variant="h2"
            color="primary.dark"
            fontWeight={800}
            sx={{ fontSize: { xs: 40, md: 56 } }}
            dangerouslySetInnerHTML={{
              __html: gettext(
                'Revolutionising<br><span class="gradient">Paediatric Research</span>'
              ),
            }}
          />
          <Typography variant="body2" color="primary.dark">
            {gettext(
              'Innovative clinical trials without the administrative burden. ' +
                'Elixir AI empowers paediatric doctors to effortlessly design, conduct, ' +
                'and analyse studies while reducing waiting lists and costs.'
            )}
          </Typography>
          <Button
            href="/studies"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            {gettext('Explore Active Studies')}
          </Button>
        </Stack>
      </div>
    </div>
  )
}
