import { IMask } from 'react-imask'

import { PHONE_MASK } from 'common/constants/common'

export default function formatPhoneNumber(number: string) {
  const masked = IMask.createMask({
    mask: PHONE_MASK,
  })
  masked.resolve(number)
  return masked.value
}
