import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { keepPreviousData } from '@tanstack/react-query'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'

import { PaginationResponse, PatientResponse, Study } from 'types'
import { access, CheckAccess, useCheckAccess } from 'common/access'
import { getLabelStatuses } from 'common/constants/labelStatus'
import { useHospitals } from 'common/api/hospital/useHospitals'
import useQueryWithFilters from 'common/hooks/useQueryWithFilters'
import { useAuth } from 'common/services/auth'
import { useTranslations } from 'common/services/translations'
import FilterSelect from 'common/components/FilterSelect'
import ModalTrigger from 'common/components/ModalTrigger'
import PatientForm from 'common/components/patient/Form'
import SearchInput from 'common/components/SearchInput'
import Table from 'common/components/Table'
import { useChangePatientStatus } from 'common/api/patient'

import getColumns from './getColumns'
import styles from './PatientsList.module.scss'

type Filters = {
  offset?: string
  limit?: string
  ordering?: string
  search?: string
  has_label?: string
  study?: Study['uuid'][]
  hospital?: string | string[]
}

const defaultParams = {
  offset: '0',
  limit: '50',
  ordering: 'name',
}

export default function PatientsList() {
  const { currentStudy } = useAuth()
  const { gettext } = useTranslations()
  const navigate = useNavigate()
  const { data: hospitals } = useHospitals()
  const [isStudyAdmin] = useCheckAccess(access.F_STUDY_ADMIN)

  const { data, isFetching, filters, addFilters, refetch, resetFilters } =
    useQueryWithFilters<PaginationResponse<PatientResponse>, Filters>(
      'patients',
      'patients',
      {
        ...defaultParams,
        study: currentStudy?.uuid ? [currentStudy.uuid] : undefined,
      },
      { placeholderData: keepPreviousData }
    )

  const { mutateAsync: changePatientStatus, isPending } =
    useChangePatientStatus(refetch)

  useEffect(() => {
    refetch()
  }, [currentStudy?.uuid, refetch])

  const columns = useMemo(
    () =>
      getColumns(
        gettext,
        refetch,
        isStudyAdmin,
        changePatientStatus,
        isPending
      ),
    [gettext, refetch, isStudyAdmin, changePatientStatus, isPending]
  )
  const isFiltered =
    'has_label' in filters || 'search' in filters || 'hospital' in filters

  const newPatient = (
    <ModalTrigger
      title={gettext('New Patient')}
      description={gettext('Add patient’s details.')}
      component={PatientForm}
      componentProps={{ refetch: () => refetch() }}
    >
      <Button variant="contained" sx={{ flexShrink: 0 }}>
        {gettext('New Patient')}
      </Button>
    </ModalTrigger>
  )

  const handleClick = (patient: PatientResponse) => {
    navigate(patient.uuid)
  }

  return (
    <Grid container wrap="nowrap" flexDirection="column" maxHeight="100%">
      <Grid container gap={1} mb={2.5}>
        <SearchInput
          name="search"
          value={filters.search}
          placeholder={gettext('Search for patient')}
          sx={{ width: 380 }}
          onChange={addFilters}
        />
        <CheckAccess access={access.F_STUDY_ADMIN}>
          <FilterSelect
            value={filters.hospital}
            name="hospital"
            label={gettext('Hospital')}
            options={hospitals ?? []}
            labelKey="title"
            valueKey="uuid"
            multiple
            onChange={addFilters}
          />
        </CheckAccess>
        <FilterSelect
          value={filters.has_label}
          name="has_label"
          label={gettext('Label Status')}
          options={getLabelStatuses(gettext)}
          multiple={false}
          onChange={addFilters}
        />
        {isFiltered && (
          <>
            <Divider orientation="vertical" sx={{ height: 'auto' }} />
            <Button variant="text" onClick={resetFilters}>
              {gettext('Clear all')}
            </Button>
          </>
        )}
        <Box ml="auto">{newPatient}</Box>
      </Grid>
      <Table<PatientResponse>
        columns={columns}
        data={data?.results}
        isLoading={isFetching}
        count={data?.count}
        offset={Number(filters.offset)}
        limit={Number(filters.limit)}
        ordering={filters.ordering}
        noResultProps={{
          title: filters.search
            ? gettext('No results for your search')
            : gettext('Looks like there are no patients yet.'),
          description: filters.search
            ? undefined
            : gettext("Ready to create one? Let's Get Started!"),
          content: filters.search ? undefined : newPatient,
          sx: { mt: '120px' },
        }}
        getRowClassName={() => styles.row}
        onChange={addFilters}
        onRowClick={handleClick}
      />
    </Grid>
  )
}
