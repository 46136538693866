import { Field } from 'formik'
import { Grid } from '@mui/material'
import { startCase } from 'lodash-es'

import { LabelTypeSchema } from 'types'

import { fieldsMapper, fieldsPropsMapper } from './utils'

type LabelFieldsProps = {
  schema?: LabelTypeSchema[]
}

export default function LabelFields({ schema }: LabelFieldsProps) {
  const renderField = (field: LabelTypeSchema) => {
    const label = startCase(field.field_key)
    const propsMapper = fieldsPropsMapper[field.field_type]
    const props =
      typeof propsMapper === 'function' ? propsMapper(label) : propsMapper
    return (
      <Grid key={field.field_key} item xs={12}>
        <Field
          component={fieldsMapper[field.field_type]}
          name={`attributes.${field.field_key}`}
          label={label}
          {...props}
        />
      </Grid>
    )
  }

  return <>{schema?.map(renderField)}</>
}
