import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

type LSValue =
  | string
  | number
  | boolean
  | { [key: string | number]: LSValue | LSValue[] }

const useLocalStorage = <T extends LSValue | null>(
  key: string,
  defaultValue?: T
) => {
  const [value, setValue] = useState<T | null>(() => {
    try {
      const storedValue = localStorage.getItem(key)
      // @ts-expect-error JSON.parse handle null without error
      const parsedValue: T | null = JSON.parse(storedValue)
      return parsedValue ?? defaultValue ?? null
    } catch (error) {
      return defaultValue ?? null
    }
  })

  const setLocalStorageValue: Dispatch<SetStateAction<T | null>> = useCallback(
    (value) => {
      setValue(value)
      try {
        if (value == null) {
          localStorage.removeItem(key)
        } else {
          localStorage.setItem(key, JSON.stringify(value))
        }
      } catch (error) {
        console.error(error)
      }
    },
    [key]
  )

  useEffect(() => {
    setLocalStorageValue(value)
  }, [value, setLocalStorageValue])

  return [value, setLocalStorageValue] as const
}

export default useLocalStorage
