import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { useTranslations } from 'common/services/translations'
import mission from 'assets/img/mission.png'

import styles from './Home.module.scss'

export default function AboutSection() {
  const { gettext } = useTranslations()

  return (
    <div className={styles.about}>
      <div className={styles.aboutContent}>
        <Grid container spacing={4} alignItems="center">
          <Grid
            item
            xs={12}
            md={6}
            sx={{ textAlign: { sm: 'center', md: 'left' } }}
          >
            <Box
              component="img"
              src={mission}
              alt="Mission"
              sx={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={3}>
              <div>
                <Typography
                  variant="subtitle2"
                  color="secondary.light"
                  fontWeight={600}
                  mb={1}
                >
                  {gettext('About Us')}
                </Typography>
                <Typography variant="h4" color="primary.dark" fontWeight={700}>
                  {gettext('Empowering Paediatric Innovation')}
                </Typography>
              </div>
              <Typography
                variant="body2"
                color="primary.dark"
                sx={{ maxWidth: 560 }}
              >
                {gettext(
                  'Elixir AI is revolutionising paediatric research by removing barriers and fostering innovation. ' +
                    'Our platform enables doctors to conduct meaningful studies without the traditional burdens of paperwork and high costs. ' +
                    "By bridging the gap between clinical practice and cutting-edge research, we're accelerating the path to better child health outcomes."
                )}
              </Typography>
              <Stack spacing={1.75}>
                <Typography
                  variant="subtitle2"
                  color="secondary.light"
                  fontWeight={600}
                  mb={1}
                >
                  {gettext('Why Elixir AI?')}
                </Typography>
                <Stack spacing={1.5} direction="row" alignItems="center">
                  <CheckCircleIcon
                    sx={{ fontSize: 24, color: 'primary.main' }}
                  />
                  <Typography variant="body2">
                    <strong>{gettext('Cost-Effective')}</strong> <br />
                    {gettext(
                      'Our platform is free for doctors, with any grant funding shared between the collaborators.'
                    )}
                  </Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" alignItems="center">
                  <CheckCircleIcon
                    sx={{ fontSize: 24, color: 'primary.main' }}
                  />
                  <Typography variant="body2" mr={0.5}>
                    <strong>{gettext('Precision Medicine')}</strong>
                    <br />
                    {gettext(
                      'Easily segment patients by severity, disease, or physiological traits to tailor treatments and improve outcomes.'
                    )}
                  </Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" alignItems="center">
                  <CheckCircleIcon
                    sx={{ fontSize: 24, color: 'primary.main' }}
                  />
                  <Typography variant="body2" mr={0.5}>
                    <strong>{gettext('Fast Implementation')}</strong>
                    <br />
                    {gettext(
                      'Test ideas to reduce waiting lists and departmental costs, turning your insights into actionable improvements.'
                    )}
                  </Typography>
                </Stack>
                <Stack spacing={1.5} direction="row" alignItems="center">
                  <CheckCircleIcon
                    sx={{ fontSize: 24, color: 'primary.main' }}
                  />
                  <Typography variant="body2" mr={0.5}>
                    <strong>{gettext('Collaborative Network')}</strong>
                    <br />
                    {gettext(
                      'Join a community of forward-thinking paediatricians, plus connect with parents and scientists to amplify your research impact.'
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
