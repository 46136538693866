import { RouterProvider } from 'react-router-dom'

import AuthProvider from 'common/services/auth'

import router from './router'
import AppProvider from './AppProvider'

export default function App() {
  return (
    <AppProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </AppProvider>
  )
}
