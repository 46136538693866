import { Choice, RequestStatus } from 'types'

export function getRequestStatus(
  gettext: (text: string) => string
): Choice<RequestStatus>[]
export function getRequestStatus(
  gettext: (text: string) => string,
  status: RequestStatus
): Choice<RequestStatus>
export function getRequestStatus(
  gettext: (text: string) => string,
  status?: RequestStatus
): Choice<RequestStatus> | Choice<RequestStatus>[] {
  const choices: Record<RequestStatus, Choice<RequestStatus>> = {
    sent: { value: 'sent', label: gettext('Sent') },
    review: { value: 'review', label: gettext('Review') },
    expired: { value: 'expired', label: gettext('Expired') },
    complete: { value: 'complete', label: gettext('Complete') },
  }

  return status ? choices[status] : Object.values(choices)
}
