import Badge from '@mui/material/Badge'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

type OptionValue = string | number

type Props<Option extends Record<string, OptionValue>> = Pick<
  SelectProps,
  'multiple'
> & {
  value?: OptionValue | OptionValue[]
  name: string
  label: string
  options: Option[]
  valueKey?: keyof Option
  labelKey?: keyof Option
  onChange: (props: { [k: string]: OptionValue[] }) => void
}

export default function FilterSelect<
  Option extends Record<string, OptionValue>,
>({
  value = [],
  name,
  label,
  options,
  valueKey = 'value',
  labelKey = 'label',
  multiple = true,
  onChange,
}: Props<Option>) {
  const selectValue = [value].flat()

  const renderValue = (selected: OptionValue[]) => {
    return (
      <>
        {label}
        {Boolean(selected.length) && (
          <Badge
            badgeContent={selected.length}
            color="primary"
            sx={{
              '.MuiBadge-badge': {
                position: 'static',
                ml: '6px',
                transform: 'none',
              },
            }}
          />
        )}
      </>
    )
  }

  const handleChange = (event: SelectChangeEvent<OptionValue[]>) => {
    const value = event.target.value
    onChange({ [name]: typeof value === 'string' ? value.split(',') : value })
  }

  return (
    <Select
      value={selectValue}
      multiple={multiple}
      displayEmpty
      IconComponent={KeyboardArrowDownIcon}
      sx={{
        '.MuiSelect-select': {
          color: 'primary.main',
          fontSize: '0.8125rem',
          lineHeight: 1.38,
          fontWeight: 500,
        },
        '.MuiSelect-icon': {
          color: 'primary.main',
        },
      }}
      renderValue={renderValue}
      onChange={handleChange}
    >
      {options.map(({ [valueKey]: optionValue, [labelKey]: optionLabel }) => (
        <MenuItem key={optionValue} value={optionValue} sx={{ py: 0, pl: 0 }}>
          <Checkbox checked={selectValue.includes(optionValue)} />
          <ListItemText
            primary={optionLabel}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      ))}
    </Select>
  )
}
