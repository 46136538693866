import { useCallback, useMemo, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import useFormSubmit from 'common/hooks/useFormSubmit'
import { useTranslations } from 'common/services/translations'

import getValidationSchema from './validationSchema'
import ForgotPasswordForm from './ForgotPasswordForm'
import ForgotPasswordSuccess from './ForgotPasswordSuccess'

type ForgotValues = {
  email: string
}

const initialValues: ForgotValues = {
  email: '',
}

export default function ForgotPassword() {
  const [success, setSuccess] = useState(false)
  const { gettext } = useTranslations()
  const validationSchema = useMemo(
    () => getValidationSchema(gettext),
    [gettext]
  )
  const { generalError, submitForm } = useFormSubmit<ForgotValues>(
    'accounts/password/reset'
  )

  const handleSuccess = useCallback(() => setSuccess((success) => !success), [])
  const handleSubmit = useCallback(
    (data: ForgotValues, actions: FormikHelpers<ForgotValues>) => {
      return submitForm({ data, actions, onSuccess: handleSuccess })
    },
    [handleSuccess, submitForm]
  )

  return (
    <>
      {generalError && (
        <Alert severity="error" color="error" sx={{ mb: 2 }}>
          <AlertTitle>{gettext('Error')}</AlertTitle>
          {generalError}
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) =>
          success ? (
            <ForgotPasswordSuccess onClick={handleSuccess} />
          ) : (
            <ForgotPasswordForm isSubmitting={isSubmitting} />
          )
        }
      </Formik>
    </>
  )
}
