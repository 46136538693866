import { useEffect, useMemo, useState } from 'react'
import { keepPreviousData } from '@tanstack/react-query'
import { isEmpty } from 'lodash-es'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'

import { PaginationResponse, RequestStatus, RequestType, Study } from 'types'
import { access, CheckAccess, useCheckAccess } from 'common/access'
import { useAuth } from 'common/services/auth'
import { useForms } from 'common/api/forms'
import { useHospitals } from 'common/api/hospital/useHospitals'
import { getRequestStatus } from 'common/constants/requestStatus'
import useQueryWithFilters from 'common/hooks/useQueryWithFilters'
import { useTranslations } from 'common/services/translations'
import Drawer from 'common/components/request/Drawer'
import FilterSelect from 'common/components/FilterSelect'
import ModalTrigger from 'common/components/ModalTrigger'
import RequestForm from 'common/components/request/Form'
import SearchInput from 'common/components/SearchInput'
import Table from 'common/components/Table'

import getColumns from './getColumns'
import RequestDatePicker from './RequestDatePicker'
import styles from './Requests.module.scss'

type Filters = {
  offset?: string
  limit?: string
  ordering?: string
  status?: RequestStatus[]
  created?: string
  search?: string
  study?: Study['uuid'][]
  form?: string[]
  hospital?: string | string[]
}

const defaultParams = {
  offset: '0',
  limit: '50',
  ordering: '-created',
}

export default function Requests() {
  const { currentStudy } = useAuth()
  const { data: forms } = useForms({
    study: currentStudy ? [currentStudy.uuid] : [],
  })
  const { data: hospitals } = useHospitals()
  const [drawerId, setDrawerId] = useState<string | null>(null)
  const { gettext } = useTranslations()
  const [isStudyAdmin] = useCheckAccess(access.F_STUDY_ADMIN)

  const { data, isFetching, filters, addFilters, refetch, resetFilters } =
    useQueryWithFilters<PaginationResponse<RequestType>, Filters>(
      'patients/requests',
      'patients/requests',
      {
        ...defaultParams,
        study: currentStudy?.uuid ? [currentStudy.uuid] : undefined,
      },
      { placeholderData: keepPreviousData }
    )

  useEffect(() => {
    refetch()
  }, [currentStudy?.uuid, refetch])

  const columns = useMemo(
    () => getColumns(gettext, refetch, isStudyAdmin),
    [gettext, refetch, isStudyAdmin]
  )
  const isFiltered = (
    ['status', 'created', 'search', 'form', 'hospital'] as Array<keyof Filters>
  ).some((name) => !isEmpty(filters[name]))

  const newRequest = (
    <ModalTrigger
      title={gettext('New Request')}
      description={gettext(
        'Find an existing Patient or create a new one using the Search bar.'
      )}
      component={RequestForm}
      componentProps={{ refetch }}
    >
      <Button variant="contained">{gettext('New Request')}</Button>
    </ModalTrigger>
  )

  return (
    <Grid container wrap="nowrap" flexDirection="column" maxHeight="100%">
      <Grid container mb={2.5} gap={1}>
        <SearchInput
          name="search"
          value={filters.search}
          placeholder={gettext('Search')}
          sx={{ width: 380 }}
          onChange={addFilters}
        />
        <CheckAccess access={access.F_STUDY_ADMIN}>
          <FilterSelect
            value={filters.hospital}
            name="hospital"
            label={gettext('Hospital')}
            options={hospitals ?? []}
            labelKey="title"
            valueKey="uuid"
            multiple
            onChange={addFilters}
          />
        </CheckAccess>
        <FilterSelect
          value={filters.status}
          name="status"
          label={gettext('Status')}
          options={getRequestStatus(gettext)}
          onChange={addFilters}
        />
        <FilterSelect
          value={filters.form}
          name="form"
          label={gettext('Request type')}
          options={forms ?? []}
          labelKey="title"
          valueKey="uuid"
          multiple
          onChange={addFilters}
        />
        <RequestDatePicker
          value={filters.created}
          name="created"
          label={gettext('Request Date')}
          onChange={addFilters}
        />
        {isFiltered && (
          <>
            <Divider orientation="vertical" sx={{ height: 'auto' }} />
            <Button variant="text" onClick={resetFilters}>
              {gettext('Clear all')}
            </Button>
          </>
        )}
        <Box ml="auto">{newRequest}</Box>
      </Grid>
      <Table<RequestType>
        columns={columns}
        data={data?.results}
        isLoading={isFetching}
        count={data?.count}
        offset={Number(filters.offset)}
        limit={Number(filters.limit)}
        ordering={filters.ordering}
        noResultProps={{
          title: isFiltered
            ? gettext('No results for your search')
            : gettext('Looks like there are no requests yet.'),
          description: isFiltered
            ? undefined
            : gettext("Ready to create one? Let's Get Started!"),
          content: isFiltered ? undefined : newRequest,
          sx: { mt: '120px' },
        }}
        getRowClassName={() => styles.row}
        onChange={addFilters}
        onRowClick={(record) => setDrawerId(record.uuid)}
      />
      <Drawer
        uuid={drawerId}
        onClose={() => setDrawerId(null)}
        refetch={refetch}
      />
    </Grid>
  )
}
