import styles from './TermsAndConditions.module.scss'

import React from 'react'

const TermsAndConditions: React.FC = () => {
  return (
    <div className={styles.termsAndConditionsContent}>
      <h1>
        Terms and Conditions for the Supply and Use of the Elixir Platform
      </h1>

      <p>
        Our full company name is Elixir AI Ltd. We are a company registered in
        England & Wales under company number 1075526. Our registered office is 7
        Florence Gardens, Manningtree, CO11 2FB.
      </p>

      <p>
        This document, together with the Data Processing Agreement, Data Sharing
        Agreement, Collaboration Agreement, and any other documents referred to,
        sets out the terms and conditions on which we supply the Elixir Platform
        for clinical research and patient care.
      </p>

      <p>
        These terms and conditions of service apply from the moment healthcare
        staff or research participants are allowed by their employing
        organisation or research study to create an account with Elixir. Please
        read these Terms and Conditions carefully. By using our software, you
        agree to be bound by these terms and conditions.
      </p>

      <h2>1. Provision of Service</h2>
      <p>
        Elixir AI Ltd. aims to provide its platform for child health research
        and care on a fair and reasonable basis to all users. We strive to
        deliver a high-quality service at all times. However, as our service
        relies on third-party services (e.g., AWS), we cannot guarantee 100%
        availability. We may suspend the Elixir Platform&apos;s service if any
        part of our system requires maintenance. We will minimise disruption by
        ensuring this occurs infrequently, keeping downtime as short as
        reasonably possible, and scheduling maintenance for evenings and
        weekends. We welcome feedback for service improvement at{' '}
        <a href="mailto:info@elixir.ai">info@elixir.ai</a>.
      </p>

      <h2>2. Acceptable Use</h2>
      <p>
        The Elixir Platform is to be used solely for the benefit of clinical
        research and patient care in accordance with these Terms and Conditions.
        We reserve the right to immediately suspend or cancel your access to any
        or all services if we determine that you have been using the Elixir
        Platform inappropriately or if resource usage is abnormally high.
      </p>
      <p>
        We prohibit the use of our services for transmitting obscene or
        otherwise inappropriate content, as determined at our discretion. You
        warrant that you will not use or attempt to use the Elixir platform for
        any unlawful purpose or any purpose prohibited by these terms,
        conditions, or notices.
      </p>
      <p>
        We reserve the right to refuse, at our sole discretion, to provide our
        services to any organisation which, in our opinion, will make
        inappropriate use of the Elixir Platform.
      </p>

      <h2>3. Data Ownership and Processing</h2>
      <p>As outlined in our Data Sharing Agreement:</p>
      <ul>
        <li>
          NHS Trusts or Universities retain ownership of all existing patient
          data, including but not limited to blood test results, diagnoses,
          imaging and investigations history, clinical history, surgical
          history, and existing clinical images or data collected as part of
          routine clinical care.
        </li>
        <li>
          Elixir owns all rights to new data collected directly from parents
          through our software, including clinical information and any photos,
          videos, or audio recordings submitted specifically for research
          purposes.
        </li>
        <li>
          Both parties grant each other a non-exclusive, royalty-free licence to
          use the other party&apos;s owned data for the purposes of
          collaboration and as specified in the research protocol.
        </li>
      </ul>

      <h2>4. Charging and Payment Policies</h2>
      <p>
        No initial subscription or set-up fees will be charged. We reserve the
        right to change the pricing of our services at any time, but we
        guarantee that such pricing changes will only take effect after one
        year&apos;s notice has been given. We may also change payment terms with
        similar notification.
      </p>
      <p>
        For transparency, the Elixir Platform itself and certain pathways are
        currently provided free of charge, and we intend to always support a
        free version of the service. Future optional features may be subject to
        charges, with the option for organisations to access such features for
        free for a limited period by participating as a pilot site.
      </p>

      <h2>5. Cancellation Policy</h2>
      <p>
        We reserve the right to cancel and close your access to the Elixir
        Platform at any time. You may close your account at any time by emailing{' '}
        <a href="mailto:info@elixir.ai">info@elixir.ai</a>.
      </p>

      <h2>6. Disclaimer</h2>
      <p>
        We make no warranties of any kind, expressed or implied, for the Elixir
        Platform service. We make no claims over the reliability, timeliness, or
        effectiveness of the Elixir Platform service or its fitness for a
        particular purpose. This includes any loss resulting from late, wrong,
        or non-delivery of messages sent through our platform or any service
        interruption accidentally caused by us.
      </p>

      <h2>7. Liability</h2>
      <p>
        Our liability is limited to what is set out in these Terms and
        Conditions. We are not liable for any loss of income, business, or
        profits, or any costs suffered due to failures in the Elixir Platform
        service that are beyond our control or could not reasonably be foreseen
        by us.
      </p>

      <h2>8. Data Protection and Privacy</h2>
      <p>
        We comply with all applicable data protection laws, including the
        General Data Protection Regulation (GDPR) and the Data Protection Act
        2018. Our practices regarding data collection, use, and storage are
        detailed in our Privacy Policy.
      </p>

      <h2>9. Research Ethics and Compliance</h2>
      <p>
        All research conducted using the Elixir Platform must adhere to
        applicable ethical guidelines and receive appropriate ethical approvals.
        Users are responsible for ensuring their research activities comply with
        all relevant regulations and institutional policies.
      </p>

      <h2>10. Intellectual Property</h2>
      <p>
        Unless otherwise agreed, all intellectual property rights in the Elixir
        Platform and any materials created by Elixir in the course of providing
        services remain the property of Elixir AI Ltd.
      </p>

      <h2>11. General</h2>
      <p>
        Your account is not transferable. We may transfer the provision of the
        service and any of our responsibilities without your permission,
        providing the service we provide to you is not reduced as a result.
      </p>
      <p>
        We may keep records of any communications between us, including verbal
        conversations and emails.
      </p>
      <p>
        Any special concessions or amendments we may make will only apply to the
        specific circumstances in which they were given.
      </p>
      <p>
        Should any dispute arise, English law will apply, and such disputes will
        be settled in an English court.
      </p>
      <p>
        If any condition of this agreement is deemed not legally effective, the
        remainder of the agreement shall remain effective, and we can replace
        the ineffective clause with an effective clause of similar meaning.
      </p>
      <p>
        We reserve the right to revise our Terms and Conditions at any time,
        particularly to meet new legislative requirements.
      </p>
      <p>
        Current Terms and Conditions are always available on the Elixir website
        and by written request.
      </p>
      <p>
        By using the Elixir Platform, you acknowledge that you have read,
        understood, and agree to be bound by these Terms and Conditions.
      </p>
    </div>
  )
}

export default TermsAndConditions
