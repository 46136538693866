import { ReactNode, useCallback, useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, CircularProgress } from '@mui/material'
import { find } from 'lodash-es'

import { Study, UserType } from 'types'
import { useStudy } from 'common/api/study'
import useChoices from 'common/hooks/useChoices'
import useLocalStorage from 'common/hooks/useLocalStorage'

import AuthContext, { SystemConfig } from './AuthContext'

type Props = {
  children: ReactNode
}

export default function AuthProvider({ children }: Props) {
  const queryClient = useQueryClient()
  const {
    data: user,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useQuery<UserType>({
    queryKey: ['accounts/me'],
    retry: false,
  })
  const { data: config, isLoading: isConfigLoading } = useQuery<SystemConfig>({
    queryKey: ['config'],
  })
  useChoices() // preload choices and cache in storage

  const [currentStudy, setCurrentStudy] = useLocalStorage<Study>('study')
  const { isError: isStudyError, isLoading: isStudyLoading } = useStudy(
    currentStudy?.uuid,
    { retry: false }
  )

  useEffect(() => {
    if (user) {
      if (!currentStudy && user.studies.length > 0) {
        setCurrentStudy(user.studies[0])
      }
      if (!isStudyLoading && isStudyError) {
        setCurrentStudy(null)
      }
    }
  }, [
    currentStudy,
    isStudyError,
    isStudyLoading,
    setCurrentStudy,
    user,
    user?.studies,
  ])

  const setStudy = useCallback(
    (studyID: Study['uuid']) => {
      if (user) {
        const study = find(user.studies, { uuid: studyID })

        if (study) {
          setCurrentStudy(study)
        }
      }
    },
    [user, setCurrentStudy]
  )

  const removeUser = () => {
    queryClient.setQueryData(['accounts/me'], null)
    queryClient.clear()
  }

  const isLoading = isUserLoading || isConfigLoading || isStudyLoading

  return (
    <AuthContext.Provider
      value={{
        user,
        config,
        setStudy,
        currentStudy,
        refetchUser,
        removeUser,
      }}
    >
      {isLoading && (
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && children}
    </AuthContext.Provider>
  )
}
