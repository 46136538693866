import { Field, Form } from 'formik'
import { Link } from 'react-router-dom'
import { TextField } from 'formik-mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useTranslations } from 'common/services/translations'

type Props = {
  isSubmitting: boolean
}

export default function ForgotPasswordForm({ isSubmitting }: Props) {
  const { gettext } = useTranslations()

  return (
    <Form>
      <Typography variant="h5" fontWeight={600} mb={4}>
        {gettext('Reset Your Password')}
      </Typography>
      <Field
        component={TextField}
        name="email"
        type="email"
        label={gettext('Email address')}
        placeholder="email@example.com"
        sx={{ width: '100%', mb: 3 }}
        InputLabelProps={{ shrink: true }}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting}
      >
        {gettext('Reset password')}
      </Button>
      <Box mt={4}>
        <Typography
          component={Link}
          to="/auth/login"
          variant="subtitle2"
          color="secondary.light"
          fontWeight={600}
        >
          {gettext('Login')}
        </Typography>
      </Box>
    </Form>
  )
}
