import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

import { interpolate } from 'common/services/translations'

export default function calculateAge(
  dob: string,
  ngettext: (singular: string, plural: string, count: number) => string
) {
  const diff = dayjs.duration(dayjs().diff(dayjs(dob)))
  const years = diff.years()
  const months = diff.months()
  const days = diff.days()
  return [
    years && interpolate(ngettext('%s year', '%s years', years), [years]),
    months && interpolate(ngettext('%s month', '%s months', months), [months]),
    !years &&
      !months &&
      interpolate(ngettext('%s day', '%s days', days), [days]),
  ]
    .filter(Boolean)
    .join(', ')
}
