import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useTranslations } from 'common/services/translations'
import ContactSection from 'layouts/PublicLayout/ContactSection'
import FAQSection from 'layouts/PublicLayout/FAQSection'
import { PublicStudy } from 'types'

import styles from './StudyDetails.module.scss'

export default function StudyDetails() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { gettext } = useTranslations()

  const { data, isLoading, isError } = useQuery<PublicStudy>({
    queryKey: [`studies/public/${id}`],
    enabled: Boolean(id),
  })

  if (isError) {
    navigate('/studies')
    return null
  }

  return (
    <>
      <div className={styles.wrapper}>
        {isLoading && (
          <CircularProgress sx={{ mx: 'auto', display: 'block' }} />
        )}
        {!isLoading && (
          <>
            <Stack
              spacing={3}
              alignItems="center"
              sx={{ maxWidth: 690, mx: 'auto' }}
            >
              <Button href="/studies" startIcon={<ArrowBackIcon />}>
                {gettext('Go To all studies')}
              </Button>
              {data?.name && (
                <Typography
                  variant="h3"
                  color="primary.dark"
                  fontWeight={800}
                  textAlign="center"
                >
                  {data.name}
                </Typography>
              )}
              {data?.page_content?.headline && (
                <Typography
                  variant="body2"
                  color="primary.dark"
                  textAlign="center"
                >
                  {data.page_content.headline}
                </Typography>
              )}
              <Button
                LinkComponent="a"
                href={`/registration/${id}`}
                variant="contained"
                size="large"
              >
                {gettext('Join Study')}
              </Button>
            </Stack>
            {data?.page_content?.hero_media &&
              data?.page_content?.hero_type === 'image' && (
                <Box
                  component="img"
                  src={data.page_content.hero_media}
                  alt={data?.name}
                  className={styles.hero}
                  sx={{ mt: { xs: 4, md: 6 } }}
                />
              )}
            {data?.page_content?.hero_media &&
              data?.page_content?.hero_type === 'video' && (
                <Box
                  component="video"
                  src={data.page_content.hero_media}
                  controls
                  autoPlay
                  muted
                  className={styles.hero}
                  sx={{ mt: { xs: 4, md: 6 } }}
                />
              )}
            <div className={styles.details}>
              <Stack
                spacing={3}
                alignItems="start"
                sx={{ maxWidth: 960, mx: 'auto' }}
              >
                {data?.page_content?.study_mission && (
                  <>
                    <Typography
                      variant="h4"
                      color="primary.dark"
                      fontWeight={700}
                    >
                      {gettext('Study Mission')}
                    </Typography>
                    <Typography variant="body1" color="primary.dark">
                      {data.page_content.study_mission}
                    </Typography>
                  </>
                )}
                {data?.page_content?.study_overview && (
                  <>
                    <Typography
                      variant="h4"
                      color="primary.dark"
                      fontWeight={700}
                    >
                      {gettext('Study Details')}
                    </Typography>
                    {data.page_content.study_overview?.text && (
                      <Typography variant="body1" color="primary.dark">
                        {data.page_content.study_overview.text}
                      </Typography>
                    )}
                    {data.page_content.study_overview?.items?.map((item) => (
                      <Stack
                        key={item.name}
                        spacing={1.5}
                        direction="row"
                        alignItems="center"
                      >
                        <CheckCircleIcon
                          sx={{ fontSize: 24, color: 'secondary.main' }}
                        />
                        <Typography variant="body2" mr={0.5}>
                          <strong>{item.name}:</strong> {item.text}
                        </Typography>
                      </Stack>
                    ))}
                  </>
                )}
                {data?.page_content?.study_details && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.page_content?.study_details,
                    }}
                  />
                )}
                <Button
                  LinkComponent="a"
                  href={`/registration/${id}`}
                  variant="contained"
                  size="large"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  {gettext('Join Study')}
                </Button>
              </Stack>
            </div>
          </>
        )}
      </div>
      {Boolean(data?.page_content?.faq?.length) && (
        <FAQSection items={data?.page_content?.faq} />
      )}
      <ContactSection />
    </>
  )
}
